@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab($width: 900px) {
  @media screen and (min-width: 768px) and (max-width: $width) {
    @content;
  }
}

@mixin header($width: 900px) {
  @media screen and (min-width: 768px) and (max-width: $width) {
    @content;
  }
}

@mixin header2($width: 900px) {
  @media screen and (min-width: 768px) and (max-width: $width) {
    @content;
  }
}

@mixin menu($width: 1000px) {
  @media screen and (min-width: 768px) and (max-width: $width) {
    @content;
  }
}

@mixin process($width: 1024px) {
  @media screen and (min-width: 768px) and (max-width: $width) {
    @content;
  }
}

@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin sp2($width: 320px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
