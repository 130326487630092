.mwform-radio-field-text {
  position: relative;
  cursor: pointer;
  padding-right: 5rem;
  display: block;
  font-size: 1.6rem;
  @include sp {
    font-size: 1.4rem;
  }
  &::before {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    background-color: #fff;
    border: 0.1rem solid #000;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    left: -3rem;
  }
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    background-color: #18a8a8;
    border-radius: 50%;
    opacity: 0;
    width: 1.6rem;
    height: 1.6rem;
    left: -2.7rem;
  }
}

.mwform-checkbox-field-text {
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
  @include sp {
    font-size: 1.2rem;
  }
  &::before {
    content: '';
    position: absolute;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 3rem;
    height: 3rem;
    border: 0.1rem solid #18a8a8;
    background-color: #fff;
    transform: translate(-120%, -50%);
    top: 50%;
    @include sp {
      width: 2rem;
      height: 2rem;
    }
  }
  &::after {
    content: '';
    position: absolute;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 1.5rem;
    height: 1rem;
    left: -1rem;
    top: 50%;
    -webkit-transform: rotate(-45deg);
    transform: translate(-120%, -50%) rotate(-45deg);
    border-left: 0.4rem solid #18a8a8;
    border-bottom: 0.4rem solid #18a8a8;
    border-right-color: #18a8a8;
    border-top-color: #18a8a8;
    opacity: 0;
    @include sp {
      border-left: 0.3rem solid #18a8a8;
      border-bottom: 0.3rem solid #18a8a8;
      left: -0.3rem;
    }
  }
}

input[value='男性']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

input[value='女性']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

input[value='検索サイトで']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

input[value='新聞・雑誌等で']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

input[value='知人から']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

input[value='その他']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

#agree-1:checked + .mwform-checkbox-field-text::after {
  opacity: 1;
}

.mw_wp_form_confirm {
  @include sp {
    padding-bottom: 3rem;
  }
}

.mw_wp_form_confirm {
  .p-contact_radioGroup {
    padding-left: 0;
  }
  .p-contact_selectTriangle {
    display: none;
  }
  .p-contact_specify {
    display: none;
  }
  .p-contact_radioItem {
    width: auto;
    padding-bottom: 0;
  }
  .p-contact_selectWrap {
    padding-left: 0;
  }
  .p-contact_field > .p-contact_question > .p-contact_requireText {
    display: none;
  }
  .p-contact_boxWrap {
    display: none;
  }
}

.mw_wp_form_input {
  .u-display {
    display: none;
  }
  .error {
    padding-top: 1rem;
  }
}

.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0;
}

.p-contact_radioGroup.u-referer span:nth-child(1) {
  width: 50%;
  padding-bottom: 2rem;
  @include sp {
    width: 100%;
  }
}

.p-contact_radioGroup.u-referer > span:nth-child(2) {
  @include sp {
    padding-bottom: 2rem;
  }
}

.p-contact_radioGroup.u-referer span:nth-child(3) {
  width: 50%;
  padding-bottom: 2rem;
  @include sp {
    width: 100%;
  }
}

.p-contact_input .p-contact_radioItem--small span:nth-child(1) {
  @include sp {
    padding-bottom: 2rem;
  }
}

.mw_wp_form .error {
  font-size: 1.4rem;
  @include sp {
    font-size: 1.2rem;
  }
}
