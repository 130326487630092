.p-index {
  &Info {
    &_wrap {
      @include pc {
        max-width: 102.4rem;
        margin: 0 auto;
      }
    }
    &_container {
      display: flex;
      @include sp {
        display: block;
      }
    }
    &Contacts {
      &_background {
        position: relative;
        @include sp {
          padding: 0 0 8rem 10%;
        }
        &::before {
          content: '';
          position: absolute;
          background-color: #18a8a8;
          width: 100vw;
          height: 100%;
          bottom: 0;
          right: 0;
          z-index: -1;
        }
      }
      &_image {
        max-width: 34.4rem;
        width: 100%;
        padding-bottom: 3rem;
      }
      &_title {
        font-family: 'Futura';
        font-size: 6rem;
        font-weight: normal;
        padding-bottom: 2.8rem;
        color: #f3ff5c;
        @include sp {
          font-size: 3rem;
          padding-bottom: 1.4rem;
        }
      }
      &_subTitle {
        color: #fff;
        font-size: 1.8rem;
        padding-bottom: 5rem;
        @include sp {
          padding-bottom: 2.5rem;
        }
      }
      &_button {
        width: 90%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: width 0.1s linear;
        @include pc {
          margin-bottom: 8rem;
        }
        &:hover {
          width: 100%;
          .p-indexInfoContacts_buttonText {
            transform: translateX(20%);
          }
          .p-indexInfoContacts_buttonArrow {
            transform: translateX(120%) rotate(45deg);
          }
        }
        &Text {
          font-size: 1.6rem;
          color: #18a8a8;
          padding: 2.7rem 3.2rem;
          transition: transform 0.1s linear;
          @include sp {
            font-size: 1.4rem;
          }
        }
        &Arrow {
          transition: transform 0.1s linear;
          display: block;
          width: 1.5rem;
          height: 1.5rem;
          border-top: solid 0.3rem #18a8a8;
          border-right: solid 0.3rem #18a8a8;
          transform: rotate(45deg);
        }
      }
    }
    &News {
      &_background {
        padding: 2rem 0 0 4rem;
        flex: 1;
        @include sp {
          display: none;
        }
      }
      &_container {
        display: flex;
      }
      &_title {
        font-family: 'Futura';
        font-size: 6rem;
        font-weight: normal;
        padding-bottom: 2.8rem;
        color: #18a8a8;
      }
      &_subTitle {
        font-size: 1.8rem;
        color: #18a8a8;
      }
      &_box {
        flex: 1;
        position: relative;
        padding-left: 4rem;
        margin-left: 4rem;
        border-left: 0.1rem solid #18a8a8;
      }
      &_list {
        padding-bottom: 3rem;
      }
      &_date {
        font-weight: bold;
        color: #18a8a8;
        padding-bottom: 1.5rem;
        border-bottom: 0.1rem solid;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        display: inline-block;
      }
      &_text {
        font-size: 1.6rem;
        line-height: 1.6;
        letter-spacing: 0.1em;
      }
      &_button {
        max-width: 23rem;
        margin-left: auto;
      }
    }
  }
}
