.p-faq {
  &_inner {
    max-width: 106.4rem;
    width: 100%;
    padding: 0 2rem;
    margin: 0 auto;
    box-sizing: border-box;
    @include sp {
      width: 90%;
      padding: 0;
    }
  }
  &_band {
    margin-bottom: 5rem;
    background: #008b8b;
    width: 90%;
    color: #fff;
    text-align: center;
    padding: 2.4rem 0;
    font-size: 2.4rem;
    @include tab {
      font-size: 2rem;
    }
    @include sp {
      margin-bottom: 3rem;
      font-size: 1.6rem;
      padding: 1.2rem 0;
      line-height: 1.3;
    }
  }
  &_label {
    display: block;
    position: relative;
    &::before {
      content: '';
      width: 3.2rem;
      height: 0.16rem;
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 2%;
      border-radius: 10em;
      transform: translateY(-50%);
      @include sp {
        width: 2.4rem;
        height: 0.2rem;
      }
    }
    &::after {
      content: '';
      width: 3.2rem;
      height: 0.16rem;
      background-color: #fff;
      position: absolute;
      top: 50%;
      right: 2%;
      border-radius: 10em;
      transform: translateY(-50%) rotate(90deg);
      transition: 0.5s;
      @include sp {
        width: 2.4rem;
        height: 0.2rem;
      }
    }
  }
  &_check {
    display: none;
  }
  &_button {
    cursor: pointer;
    background-color: #18a8a8;
    display: flex;
    align-items: center;
    padding: 2rem 1rem;
    @include sp {
      padding: 1rem 1rem;
    }
    &Text {
      color: #fff;
      font-size: 1.8rem;
      padding-left: 3.5rem;
      @include sp {
        font-size: 1.5rem;
        padding-left: 1.5rem;
        line-height: 1.3;
      }
    }
  }
  &_container {
    display: flex;
    border: 0.1rem solid #18a8a8;
    height: 0;
    transition: all 0.1s linear;
    overflow: hidden;
  }
  &_symbol {
    font-size: 4rem;
    @include sp {
      font-size: 3rem;
    }
    &--question {
      color: #fff;
    }
    &--answer {
      color: #18a8a8;
    }
  }
  &_answer {
    max-width: 76rem;
    padding-left: 4rem;
    @include sp {
      padding-left: 2rem;
    }
  }
  &_accordion {
    max-width: 92rem;
    margin: 0 auto;
    padding-bottom: 4rem;
  }
  &_text {
    font-size: 1.6rem;
    line-height: 1.75;
    @include sp {
      font-size: 1.4rem;
    }
  }
}

@for $i from 1 through 4 {
  #check#{$i}:checked + .p-faq_label::after {
    transform: translateY(-50%) rotate(0);
  }
}

@for $i from 1 through 4 {
  #check#{$i}:checked ~ #answer#{$i} {
    height: auto;
    opacity: 1;
    padding: 2rem 1rem;
  }
}
