.l-footer {
  &_inner {
    max-width: 102.4rem;
    margin: 0 auto;
    @include sp {
      width: 90%;
    }
  }
  &_bg {
    padding: 3rem 0 6rem;
    background-color: #008b8b;
    color: #fff;
    position: relative;
    @include sp {
      padding: 1rem 0rem;
    }
  }
  &_button {
    position: absolute;
    right: 0;
    width: 27rem;
    height: 8rem;
    background-color: #fff;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &Arrow {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      border-top: solid 0.3rem #18a8a8;
      border-right: solid 0.3rem #18a8a8;
      transform: rotate(-45deg);
    }
    @include sp {
      width: 6rem;
      height: 4rem;
    }
  }
  &_container {
    display: flex;
  }
  &_copyright {
    background-color: #fff;
    padding: 2rem;
    font-family: Verdana;
    text-align: center;
    @include sp {
      padding: 1rem;
    }
    &Text {
      font-size: 1.6rem;
      color: #18a8a8;
      line-height: 1.6;
      @include sp {
        font-size: 1.2rem;
      }
    }
  }
  &_access {
    font-size: 1.6rem;
    line-height: 1.6;
    @include sp {
      font-size: 1.2rem;
    }
  }
  &_profile {
    flex: 1;
  }
  &_nav {
    flex: 1;
    line-height: 2.2;
  }
  &_list {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    &::before {
      left: -2.4rem;
      content: '';
      position: absolute;
      display: block;
      width: 0.8rem;
      height: 0.8rem;
      border-top: solid 0.3rem #fff;
      border-right: solid 0.3rem #fff;
      transform: rotate(45deg);
    }
  }
}
