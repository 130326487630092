.c-contacts {
  background-color: #18a8a8;
  &_inner {
    max-width: 106.4rem;
    width: 100%;
    padding: 0 2rem 3.5rem;
    margin: 0 auto;
    box-sizing: border-box;
    @include sp {
      padding: 0;
    }
  }
  &_button {
    background-color: #fff;
    width: 29rem;
    display: flex;
    align-items: center;
    @include sp {
      justify-content: center;
    }
    &Text {
      color: #18a8a8;
      padding: 2.5rem 3rem;
      font-size: 1.6rem;
    }
    &Arrow {
      width: 1rem;
      height: 1rem;
      border-top: solid 0.2rem #18a8a8;
      border-right: solid 0.2rem #18a8a8;
      transform: rotate(45deg);
    }
  }
  &_container {
    display: flex;
    justify-content: space-between;
    @include sp {
      justify-content: center;
    }
  }
  &_image {
    img {
      @include sp {
        width: 100%;
      }
    }
  }
  &_text {
    font-size: 1.6rem;
    color: #fff;
  }
  &_title {
    padding-bottom: 1rem;
    @include sp {
      font-size: 3.4rem;
    }
  }
  &_box {
    padding-top: 2.5rem;
    &--title {
      padding-bottom: 2.5rem;
      @include sp {
        text-align: center;
      }
    }
  }
  &_image {
    max-width: 54rem;
    width: 100%;
    @include sp {
      max-width: 27rem;
      margin-left: auto;
    }
  }
}
