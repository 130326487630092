@charset "UTF-8";


/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 10px;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
}

li {
  list-style: none;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: inherit;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select,
img {
  vertical-align: bottom;
}

@media screen and (max-width: 767px) {
  html {
    font-size: calc(100vw / 32);
  }
}

body {
  font-family: 'Noto Sans', sans-serif;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: 'メイリオ', Meiryo;
  }
}

img {
  width: 100%;
  display: block;
  height: auto;
}

p {
  letter-spacing: 0.1em;
}

@media screen and (min-width: 768px) {
  .pc {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .sp {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sp {
    display: block;
  }
}

.sp-min {
  display: none;
}

@media screen and (max-width: 320px) {
  .sp-min {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .sp-flex {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sp-flex {
    display: flex;
  }
}

::placeholder {
  color: #acacac;
}

.l-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 65535;
}

.l-header_bg {
  background-color: #008b8b;
  color: #fff;
}

.l-header_logo {
  margin: 0 0 0 3rem;
}

@media screen and (max-width: 767px) {
  .l-header_logo {
    margin: 0;
  }
}

.l-header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 14rem;
}

@media screen and (max-width: 767px) {
  .l-header_container {
    height: 7rem;
    padding: 0 1.8rem;
    position: relative;
  }
}

.l-header_nav {
  display: flex;
  text-align: center;
  align-items: center;
  height: 14rem;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  .l-header_nav {
    width: 100%;
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    height: 0;
  }
}

.l-header_navItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #008b8b;
  position: relative;
}

@media screen and (min-width: 768px) {
  .l-header_navItem {
    max-width: 13rem;
    border-left: #fff solid 0.1rem;
    height: 5rem;
    padding: 0 0.3rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1529px) {
  .l-header_navItem {
    max-width: 10rem;
  }
}

@media screen and (max-width: 767px) {
  .l-header_navItem {
    width: auto;
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
    position: relative;
  }
}

.l-header_navContact {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f3ff5c;
  position: relative;
}

@media screen and (min-width: 768px) {
  .l-header_navContact:hover {
    background-color: #f5f5f5;
  }
}

@media screen and (min-width: 768px) {
  .l-header_navContact {
    max-width: 13rem;
    border-left: #fff solid 0.1rem;
    padding: 0 0.3rem;
    border: none;
    height: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1529px) {
  .l-header_navContact {
    max-width: 10rem;
  }
}

@media screen and (max-width: 767px) {
  .l-header_navContact {
    width: auto;
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
    position: relative;
  }
}

.l-header_navLink {
  font-size: 1.6rem;
  color: #fff;
  position: relative;
  line-height: 1.5;
}

@media screen and (min-width: 768px) and (max-width: 1529px) {
  .l-header_navLink {
    font-size: 1.2rem;
  }
}

.l-header_navLink::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 0.2rem;
  left: 0;
  bottom: -1.5rem;
  background-color: #f3ff5c;
  transition: all 0.3s 0s ease;
}

.l-header_navLink--green {
  font-size: 1.4rem;
  color: #008b8b;
  position: relative;
}

@media screen and (min-width: 768px) and (max-width: 1529px) {
  .l-header_navLink--green {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 768px) {
  .l-header_navWrap {
    margin-left: 2rem;
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .l-header_navWrap {
    display: contents;
  }
}

.l-header_navWrap::after {
  background-color: #fff;
  content: '';
  width: 0vw;
  height: 0vh;
  z-index: -17;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
}

.l-header_sentence {
  font-size: 1.2rem;
}

@media screen and (max-width: 767px) {
  .l-header_sentence {
    font-size: 1rem;
  }
}

.l-header_strBox {
  padding: 1rem 2rem;
  background-color: #008b8b;
  color: #f3ff5c;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) and (max-width: 1529px) {
  .l-header_strBox {
    width: 7rem;
    padding: 0.6rem 0.3rem;
    margin: 0 auto 0.8rem;
  }
}

@media screen and (max-width: 767px) {
  .l-header_strBox {
    font-family: 'Futura';
    padding: 0.7rem 1.4rem;
    margin-bottom: 0.8rem;
  }
}

.l-header_arrow, .l-header_arrow--green {
  right: 10%;
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  border-top: solid 0.2rem #fff;
  border-right: solid 0.2rem #fff;
  transform: rotate(45deg);
}

.l-header_arrow--green {
  border-top: solid 0.2rem #18a8a8;
  border-right: solid 0.2rem #18a8a8;
}

@media screen and (min-width: 768px) {
  .l-header_navContact:hover > .l-header_strBox {
    color: #f5f5f5;
  }
}

@media screen and (min-width: 768px) {
  .l-header_navLink:hover::after {
    background-color: #f3ff5c;
    width: 100%;
  }
}

.l-footer_inner {
  max-width: 102.4rem;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .l-footer_inner {
    width: 90%;
  }
}

.l-footer_bg {
  padding: 3rem 0 6rem;
  background-color: #008b8b;
  color: #fff;
  position: relative;
}

@media screen and (max-width: 767px) {
  .l-footer_bg {
    padding: 1rem 0rem;
  }
}

.l-footer_button {
  position: absolute;
  right: 0;
  width: 27rem;
  height: 8rem;
  background-color: #fff;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.l-footer_buttonArrow {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-top: solid 0.3rem #18a8a8;
  border-right: solid 0.3rem #18a8a8;
  transform: rotate(-45deg);
}

@media screen and (max-width: 767px) {
  .l-footer_button {
    width: 6rem;
    height: 4rem;
  }
}

.l-footer_container {
  display: flex;
}

.l-footer_copyright {
  background-color: #fff;
  padding: 2rem;
  font-family: Verdana;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .l-footer_copyright {
    padding: 1rem;
  }
}

.l-footer_copyrightText {
  font-size: 1.6rem;
  color: #18a8a8;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .l-footer_copyrightText {
    font-size: 1.2rem;
  }
}

.l-footer_access {
  font-size: 1.6rem;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .l-footer_access {
    font-size: 1.2rem;
  }
}

.l-footer_profile {
  flex: 1;
}

.l-footer_nav {
  flex: 1;
  line-height: 2.2;
}

.l-footer_list {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}

.l-footer_list::before {
  left: -2.4rem;
  content: '';
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-top: solid 0.3rem #fff;
  border-right: solid 0.3rem #fff;
  transform: rotate(45deg);
}

.l-inner {
  max-width: 1024px;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .l-inner {
    width: 95%;
  }
}

.l-wrap {
  max-width: 1280px;
}

.c-title {
  font-family: 'Futura';
  font-size: 6rem;
  font-weight: normal;
  padding-bottom: 2.8rem;
}

.c-title--yellow {
  color: #f3ff5c;
}

.c-title--green {
  color: #18a8a8;
}

.c-subTitle {
  padding: 1rem 4rem;
  position: relative;
  font-size: 2.4rem;
  font-weight: bold;
  color: #18a8a8;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .c-subTitle {
    padding: 1rem 0;
    font-size: 2rem;
  }
}

.c-subTitle::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 0.1rem solid #18a8a8;
}

.c-subTitle::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60%;
  border-bottom: 0.4rem solid #18a8a8;
}

.c-logo {
  display: flex;
  align-items: center;
  max-width: 29.4rem;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .c-logo {
    width: 80%;
  }
}

.c-logo_name, .c-logo_name--footer, .c-logo_name--header {
  font-family: 'Futura';
  color: #f3ff5c;
}

.c-logo_name--footer {
  font-size: 2.9rem;
}

@media screen and (max-width: 767px) {
  .c-logo_name--footer {
    font-size: 2rem;
  }
}

.c-logo_name--header {
  font-size: 2.5rem;
}

@media screen and (max-width: 767px) {
  .c-logo_name--header {
    font-size: 2.1rem;
  }
}

.c-logo_ruby {
  font-size: 1.4rem;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.c-contacts {
  background-color: #18a8a8;
}

.c-contacts_inner {
  max-width: 106.4rem;
  width: 100%;
  padding: 0 2rem 3.5rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .c-contacts_inner {
    padding: 0;
  }
}

.c-contacts_button {
  background-color: #fff;
  width: 29rem;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .c-contacts_button {
    justify-content: center;
  }
}

.c-contacts_buttonText {
  color: #18a8a8;
  padding: 2.5rem 3rem;
  font-size: 1.6rem;
}

.c-contacts_buttonArrow {
  width: 1rem;
  height: 1rem;
  border-top: solid 0.2rem #18a8a8;
  border-right: solid 0.2rem #18a8a8;
  transform: rotate(45deg);
}

.c-contacts_container {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .c-contacts_container {
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .c-contacts_image img {
    width: 100%;
  }
}

.c-contacts_text {
  font-size: 1.6rem;
  color: #fff;
}

.c-contacts_title {
  padding-bottom: 1rem;
}

@media screen and (max-width: 767px) {
  .c-contacts_title {
    font-size: 3.4rem;
  }
}

.c-contacts_box {
  padding-top: 2.5rem;
}

.c-contacts_box--title {
  padding-bottom: 2.5rem;
}

@media screen and (max-width: 767px) {
  .c-contacts_box--title {
    text-align: center;
  }
}

.c-contacts_image {
  max-width: 54rem;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .c-contacts_image {
    max-width: 27rem;
    margin-left: auto;
  }
}

.c-top_image img {
  width: 100%;
}

.c-top_title {
  position: relative;
  background-color: #18a8a8;
  width: 90%;
  text-align: center;
}

.c-top_text {
  font-size: 2.8rem;
  color: #fff;
  letter-spacing: 0.6em;
  font-weight: 400;
}

@media screen and (max-width: 767px) {
  .c-top_text {
    font-size: 2rem;
  }
}

.c-top_text--en {
  font-size: 2rem;
  color: #f3ff5c;
  font-family: 'Futura';
}

@media screen and (max-width: 767px) {
  .c-top_text--en {
    font-size: 1.4rem;
  }
}

.c-top_inner {
  position: absolute;
  bottom: 100%;
  width: 100%;
  border-radius: 2rem 0 0;
  background: none;
}

.c-top_inner::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  background: #18a8a8;
  mix-blend-mode: multiply;
  z-index: 0;
  bottom: 0;
  border-radius: 0 2rem 0 0;
}

.c-top_wrap {
  padding: 1%;
  position: relative;
}

.c-slide_button {
  width: 100%;
  height: 5rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.c-slide_button--green {
  background-color: #008b8b;
  width: 100%;
  height: 5rem;
  transition: all 0.1s linear;
  transform: translateX(50%);
}

.c-slide_button::before {
  content: '';
  background-color: #008b8b;
  width: 100%;
  height: 5rem;
  transition: all 0.1s linear;
  transform: translateX(50%);
  position: absolute;
}

.c-slide_button:hover::before {
  transform: translateX(0%);
  width: 100%;
  height: 5rem;
}

.c-slide_button:hover div {
  transform: translateX(0%);
  width: 100%;
  height: 5rem;
}

.c-slide_button:hover p.c-slide_text {
  position: absolute;
  color: #fff;
  transform: translateX(50%);
}

.c-slide_button:hover p.c-slide_arrow {
  position: absolute;
  color: #fff;
  transform: translateX(-50%) rotate(45deg);
}

.c-slide_text {
  position: absolute;
  left: 7%;
  color: #008b8b;
  z-index: 2;
  transition: all 0.1s linear;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .c-slide_text {
    font-size: 1.2rem;
    left: 3%;
  }
}

.c-slide_arrow {
  color: #fff;
  z-index: 2;
  transition: all 0.1s linear;
  position: absolute;
  left: 71%;
  display: block;
  width: 1rem;
  height: 1rem;
  border-top: solid 0.2rem #fff;
  border-right: solid 0.2rem #fff;
  transform: rotate(45deg);
}

.c-scrollDown {
  width: 13.6rem;
  height: 33rem;
  background-color: #18a8a8;
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1529px) {
  .c-scrollDown {
    width: 9.6rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-scrollDown {
    height: 26rem;
  }
}

.c-scrollDown_str {
  writing-mode: vertical-lr;
  position: relative;
  top: 4rem;
  font-size: 1.2rem;
  padding: 0.6rem;
  color: #fff;
}

.c-scrollDown_str::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 0.2rem;
  height: 8.5rem;
  background: #f3ff5c;
}

.c-scrollDown_str::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 0.2rem;
  height: 8.5rem;
  background: #008b8b;
  animation: pathmove 1.8s ease-in-out infinite;
  opacity: 0;
}

@keyframes pathmove {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 3rem;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 8.5rem;
    opacity: 1;
  }
}

.c-menu_container {
  display: flex;
  justify-content: space-between;
  max-width: 102.4rem;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .c-menu_container {
    display: block;
  }
}

.c-menu_link {
  background-color: #18a8a8;
  width: 23rem;
  color: #fff;
  padding: 1.5rem;
  position: relative;
  font-size: 1.8rem;
  display: block;
  box-sizing: border-box;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .c-menu_link {
    font-size: 1.6rem;
    margin: 0 auto;
  }
}

.c-menu_link::after {
  content: '';
  position: absolute;
  right: 5%;
  top: 50%;
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  border-top: solid 0.2rem #fff;
  border-right: solid 0.2rem #fff;
  transform: translateY(-50%) rotate(135deg);
}

.c-menu_small {
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .c-menu_small {
    font-size: 1rem;
  }
}

.c-drawer_check {
  display: none;
}

.c-drawer_box {
  position: fixed;
  width: 2.5rem;
  height: 2rem;
  right: 1.8rem;
  top: 1.8rem;
}

.c-drawer_close {
  font-size: 0;
}

.c-drawer_open {
  font-size: 1rem;
}

.c-drawer_text {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
}

.c-drawer_icon {
  left: 0.3rem;
}

.c-drawer_icon:nth-child(1) {
  display: block;
  height: 0.2rem;
  width: 2.5rem;
  border-radius: 0.3rem;
  background: #fff;
  transition: 0.5s;
  position: absolute;
  bottom: calc(100% - 0.4rem);
}

.c-drawer_icon:nth-child(2) {
  display: block;
  height: 0.2rem;
  width: 1.2rem;
  border-radius: 0.3rem;
  background: #fff;
  transition: 0.5s;
  position: absolute;
  top: 0.9rem;
}

.c-drawer_icon:nth-child(3) {
  display: block;
  height: 0.2rem;
  width: 2.5rem;
  border-radius: 0.3rem;
  background: #fff;
  transition: 0.3s;
  position: absolute;
  top: calc(100% - 0.4rem);
}

#drawer-check:checked ~ .c-drawer_box .c-drawer_open {
  color: rgba(255, 255, 255, 0);
  font-size: 0;
}

#drawer-check:checked ~ .c-drawer_box .c-drawer_close {
  opacity: 1;
  font-size: 1rem;
}

#drawer-check:checked ~ .c-drawer_box .c-drawer_icon:nth-child(1) {
  bottom: calc(100% - 1.1rem);
  transform: rotate(45deg);
  left: 0.5rem;
}

#drawer-check:checked ~ .c-drawer_box .c-drawer_icon:nth-child(2) {
  background-color: rgba(255, 255, 255, 0);
}

#drawer-check:checked ~ .c-drawer_box .c-drawer_icon:nth-child(3) {
  top: calc(100% - 1.1rem);
  transform: rotate(-45deg);
  left: 0.5rem;
}

@media screen and (max-width: 767px) {
  #drawer-check:checked ~ .l-header_navWrap > .l-header_nav > .l-header_navItem:nth-child(1) {
    border-top: 0.1rem solid #fff;
  }
}

@media screen and (max-width: 767px) {
  #drawer-check:checked ~ .l-header_navWrap > .l-header_nav > .l-header_navItem {
    height: 5.5rem;
    border-bottom: 0.1rem solid #f3ff5c;
  }
}

#drawer-check:checked ~ .l-header_navWrap::after {
  width: 100vw;
  height: 100vh;
}

.p-list_top {
  padding-bottom: 6rem;
}

.p-list_inner {
  max-width: 106.4rem;
  width: 100%;
  padding: 0 2rem 8rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-list_inner {
    width: 90%;
    padding: 0 0 4rem;
  }
}

.p-list_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.p-list_title {
  margin-bottom: 4rem;
}

@media screen and (max-width: 767px) {
  .p-list_title {
    margin-bottom: 2rem;
  }
}

.p-list_name {
  color: #fff;
  background: #18a8a8;
  text-align: center;
  padding: 1.2rem 0;
  letter-spacing: 0;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-list_name {
    font-size: 1.4rem;
  }
}

.p-list_name--small {
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .p-list_name--small {
    font-size: 1.2rem;
  }
}

.p-list_item {
  padding-bottom: 4rem;
}

.p-list_number {
  border-bottom: 0.1rem #18a8a8 solid;
  padding: 1rem 0;
  text-align: center;
  color: #18a8a8;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-list_number {
    font-size: 1.4rem;
  }
}

.p-list_list {
  border: 0.1rem solid #707070;
  padding: 3rem;
  color: #18a8a8;
  border-bottom: none;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 767px) {
  .p-list_list {
    font-size: 1.4rem;
    padding: 1.5rem;
    line-height: 1.5;
  }
}

.p-list_list:nth-child(2n) {
  background-color: #f5f5f5;
}

.p-list_list:last-child {
  border-bottom: 0.1rem solid #707070;
}

.p-list_image {
  max-width: 27.1rem;
  width: 100%;
}

.p-iso_inner {
  max-width: 106.4rem;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-iso_inner {
    width: 90%;
    padding: 0;
  }
}

.p-iso_top {
  padding-bottom: 6rem;
}

.p-iso_wrap {
  padding: 3.5rem;
}

@media screen and (max-width: 767px) {
  .p-iso_wrap {
    padding: 1.5rem;
  }
}

.p-iso_order {
  counter-reset: item;
  list-style-type: none;
  padding-left: 0;
  line-height: 3;
}

@media screen and (max-width: 767px) {
  .p-iso_order {
    line-height: 2;
  }
}

.p-iso_order--nest {
  padding-left: 2%;
  line-height: 2;
}

.p-iso_list {
  font-size: 1.6rem;
  text-indent: -1em;
  padding-left: 1em;
}

@media screen and (max-width: 767px) {
  .p-iso_list {
    font-size: 1.4rem;
  }
  .p-iso_list + .p-iso_list {
    margin-top: 1rem;
  }
}

.p-iso_list::before {
  counter-increment: item;
  content: counter(item) ".";
  padding-right: 0.5em;
  color: #18a8a8;
}

.p-iso_nest {
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-iso_nest {
    font-size: 1.4rem;
  }
}

.p-iso_text {
  font-size: 1.6rem;
  line-height: 1.75;
}

@media screen and (max-width: 767px) {
  .p-iso_text {
    font-size: 1.4rem;
  }
}

.p-faq_inner {
  max-width: 106.4rem;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-faq_inner {
    width: 90%;
    padding: 0;
  }
}

.p-faq_band {
  margin-bottom: 5rem;
  background: #008b8b;
  width: 90%;
  color: #fff;
  text-align: center;
  padding: 2.4rem 0;
  font-size: 2.4rem;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-faq_band {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .p-faq_band {
    margin-bottom: 3rem;
    font-size: 1.6rem;
    padding: 1.2rem 0;
    line-height: 1.3;
  }
}

.p-faq_label {
  display: block;
  position: relative;
}

.p-faq_label::before {
  content: '';
  width: 3.2rem;
  height: 0.16rem;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 2%;
  border-radius: 10em;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .p-faq_label::before {
    width: 2.4rem;
    height: 0.2rem;
  }
}

.p-faq_label::after {
  content: '';
  width: 3.2rem;
  height: 0.16rem;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 2%;
  border-radius: 10em;
  transform: translateY(-50%) rotate(90deg);
  transition: 0.5s;
}

@media screen and (max-width: 767px) {
  .p-faq_label::after {
    width: 2.4rem;
    height: 0.2rem;
  }
}

.p-faq_check {
  display: none;
}

.p-faq_button {
  cursor: pointer;
  background-color: #18a8a8;
  display: flex;
  align-items: center;
  padding: 2rem 1rem;
}

@media screen and (max-width: 767px) {
  .p-faq_button {
    padding: 1rem 1rem;
  }
}

.p-faq_buttonText {
  color: #fff;
  font-size: 1.8rem;
  padding-left: 3.5rem;
}

@media screen and (max-width: 767px) {
  .p-faq_buttonText {
    font-size: 1.5rem;
    padding-left: 1.5rem;
    line-height: 1.3;
  }
}

.p-faq_container {
  display: flex;
  border: 0.1rem solid #18a8a8;
  height: 0;
  transition: all 0.1s linear;
  overflow: hidden;
}

.p-faq_symbol {
  font-size: 4rem;
}

@media screen and (max-width: 767px) {
  .p-faq_symbol {
    font-size: 3rem;
  }
}

.p-faq_symbol--question {
  color: #fff;
}

.p-faq_symbol--answer {
  color: #18a8a8;
}

.p-faq_answer {
  max-width: 76rem;
  padding-left: 4rem;
}

@media screen and (max-width: 767px) {
  .p-faq_answer {
    padding-left: 2rem;
  }
}

.p-faq_accordion {
  max-width: 92rem;
  margin: 0 auto;
  padding-bottom: 4rem;
}

.p-faq_text {
  font-size: 1.6rem;
  line-height: 1.75;
}

@media screen and (max-width: 767px) {
  .p-faq_text {
    font-size: 1.4rem;
  }
}

#check1:checked + .p-faq_label::after {
  transform: translateY(-50%) rotate(0);
}

#check2:checked + .p-faq_label::after {
  transform: translateY(-50%) rotate(0);
}

#check3:checked + .p-faq_label::after {
  transform: translateY(-50%) rotate(0);
}

#check4:checked + .p-faq_label::after {
  transform: translateY(-50%) rotate(0);
}

#check1:checked ~ #answer1 {
  height: auto;
  opacity: 1;
  padding: 2rem 1rem;
}

#check2:checked ~ #answer2 {
  height: auto;
  opacity: 1;
  padding: 2rem 1rem;
}

#check3:checked ~ #answer3 {
  height: auto;
  opacity: 1;
  padding: 2rem 1rem;
}

#check4:checked ~ #answer4 {
  height: auto;
  opacity: 1;
  padding: 2rem 1rem;
}

.p-about_inner {
  max-width: 106.4rem;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-about_inner {
    width: 90%;
    padding: 0;
  }
}

.p-about_top {
  padding-bottom: 5rem;
}

@media screen and (max-width: 767px) {
  .p-about_top {
    padding-bottom: 2.5rem;
  }
}

.p-about_illust {
  max-width: 94.5rem;
  width: 100%;
  margin: 0 auto;
}

.p-about_access {
  padding-bottom: 6rem;
}

.p-about_greet {
  display: flex;
  padding-bottom: 4rem;
}

@media screen and (max-width: 767px) {
  .p-about_greet {
    display: block;
    padding-bottom: 2rem;
  }
}

.p-about_greetBox {
  padding: 4.5rem 0 4.5rem 4.5rem;
}

@media screen and (max-width: 767px) {
  .p-about_greetBox {
    padding: 1rem 0 0;
  }
}

.p-about_greetTitle {
  font-size: 2.4rem;
  color: #18a8a8;
  font-weight: bold;
  line-height: 1.5;
  border-bottom: solid 0.1rem #18abab;
  padding-bottom: 2.4rem;
  margin-bottom: 2.4rem;
}

@media screen and (max-width: 767px) {
  .p-about_greetTitle {
    font-size: 2rem;
    padding-bottom: 1.2rem;
    margin-bottom: 1.2rem;
  }
}

.p-about_greetImage {
  max-width: 45.6rem;
  width: 100%;
  margin: 0 auto;
}

.p-about_title {
  padding-bottom: 4rem;
}

.p-aboutMenu_wrap {
  max-width: 77rem;
  margin: 0 auto;
  padding: 8rem 0 10rem;
}

@media screen and (max-width: 767px) {
  .p-aboutMenu_wrap {
    padding: 4rem 0;
  }
}

@media screen and (max-width: 767px) {
  .p-aboutMenu_list:not(:last-child) {
    margin: 0 auto 2rem;
  }
}

.p-about_person {
  color: #18a8a8;
  font-size: 2rem;
  text-align: right;
}

.p-about_person--job {
  font-size: 1.7rem;
}

.p-about_text {
  font-size: 1.6rem;
  line-height: 1.75;
}

@media screen and (max-width: 767px) {
  .p-about_text {
    font-size: 1.4rem;
  }
}

.p-about_guide {
  padding: 3.5rem 0;
}

.p-about_container {
  display: flex;
}

@media screen and (max-width: 767px) {
  .p-about_container {
    display: block;
  }
}

.p-about_image {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0 4rem;
}

@media screen and (max-width: 767px) {
  .p-about_image {
    width: 100%;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
  }
}

.p-about_address {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.p-about_address + .p-about_address {
  border-top: solid 0.1rem #18abab;
  padding-top: 2.4rem;
  margin-top: 2.4rem;
}

@media screen and (max-width: 767px) {
  .p-about_address {
    display: block;
  }
}

.p-about_addressText {
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-about_addressText {
    padding-bottom: 1.5rem;
    font-size: 1.4rem;
  }
}

.p-about_item {
  width: 100%;
}

.p-about_item--left {
  padding-right: 2.5rem;
}

@media screen and (max-width: 767px) {
  .p-about_item--left {
    padding-right: 0;
  }
}

.p-about_item--right {
  padding-left: 2.5rem;
}

@media screen and (max-width: 767px) {
  .p-about_item--right {
    padding-left: 0;
  }
}

.p-about_phone {
  color: #18a8a8;
  font-weight: bold;
  font-size: 1.8rem;
  padding-bottom: 4rem;
}

@media screen and (max-width: 767px) {
  .p-about_phone {
    font-size: 1.6rem;
    padding-bottom: 2rem;
  }
}

.p-about_wrap {
  padding: 4rem;
}

@media screen and (max-width: 767px) {
  .p-about_wrap {
    padding: 2rem 0;
  }
}

.p-about_facility {
  border-bottom: #18a8a8 solid 0.1rem;
  padding-bottom: 1.5rem;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .p-about_facility {
    font-size: 1.5rem;
    padding-bottom: 0.8rem;
  }
}

.p-aboutDefinition_list {
  display: flex;
  width: 100%;
  border-left: 0.1rem solid #707070;
  border-right: 0.1rem solid #707070;
}

.p-aboutDefinition_list:first-of-type {
  border-top: 0.1rem solid #707070;
}

.p-aboutDefinition_list > .p-aboutDefinition_term {
  border-bottom: 0.1rem solid #fff;
}

.p-aboutDefinition_list:last-child > .p-aboutDefinition_term {
  border-bottom: 0.1rem solid #707070;
}

@media screen and (max-width: 767px) {
  .p-aboutDefinition_list {
    flex-direction: column;
  }
}

.p-aboutDefinition_term {
  font-size: 1.6rem;
  font-weight: bold;
  background-color: #18a8a8;
  color: #fff;
  padding: 1rem;
  width: 16%;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .p-aboutDefinition_term {
    font-size: 1.4rem;
    width: 100%;
    padding: 1rem 0;
  }
}

.p-aboutDefinition_description {
  font-size: 1.6rem;
  padding: 2rem;
  line-height: calc(28 / 16);
  letter-spacing: 0.1em;
  border-bottom: 0.1rem solid #707070;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .p-aboutDefinition_description {
    font-size: 1.4rem;
    padding: 1rem;
  }
}

.p-about_subTitle {
  padding: 1rem 4rem;
  border-bottom: 0.1rem solid #18a8a8;
  font-size: 2.4rem;
  color: #18a8a8;
}

.p-about_award {
  max-width: 49.2rem;
  width: 100%;
  margin: 0 auto;
  padding: 4rem 0;
}

@media screen and (max-width: 767px) {
  .p-about_award {
    padding: 2rem 0rem;
  }
}

.p-about_awardImage {
  padding-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .p-about_awardImage {
    padding-bottom: 1rem;
  }
}

.p-about_button {
  background-color: #18a8a8;
  color: #fff;
  text-align: center;
  border-radius: 5rem;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 0.5rem 2.5rem;
  display: block;
}

@media screen and (max-width: 767px) {
  .p-about_button {
    font-size: 1.4rem;
    width: 70%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .p-about_menu {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .p-about_menuItem {
    margin-bottom: 2rem;
  }
}

.p-about_place {
  max-width: 30rem;
  width: 100%;
  margin: 0 auto;
}

.p-about_iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

@media screen and (max-width: 767px) {
  .p-about_iframe {
    height: 90%;
  }
}

.p-about_iframeWrap {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .p-about_iframeWrap {
    max-width: 62rem;
  }
}

.p-outline_inner {
  max-width: 102.4rem;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-outline_inner {
    width: 90%;
  }
}

.p-outline_text {
  font-size: 1.6rem;
  color: #18a8a8;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .p-outline_text {
    font-size: 1.4rem;
  }
}

.p-outline_container {
  display: flex;
  border: 0.1rem solid #707070;
}

.p-outline_containerWrap {
  padding-bottom: 4rem;
}

.p-outline_container--even {
  border-top: none;
  background-color: #f5f5f5;
}

.p-outline_item {
  border-collapse: collapse;
  border: 0.1rem solid #333;
  padding: 2.5rem 2.3rem;
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .p-outline_item {
    padding: 1rem;
  }
}

.p-outline_item--wrap {
  width: 16%;
}

@media screen and (max-width: 767px) {
  .p-outline_item--wrap {
    white-space: nowrap;
  }
}

.p-outline_wrap {
  padding: 3rem;
}

@media screen and (max-width: 767px) {
  .p-outline_wrap {
    padding: 3rem 1.5rem;
  }
}

.p-outline_circle {
  width: 2rem;
  height: 2rem;
  background-color: #18a8a8;
  border-radius: 50%;
  margin: 0 auto;
}

.p-outline_band {
  margin-bottom: 5rem;
  background: #008b8b;
  width: 90%;
  color: #fff;
  text-align: center;
  padding: 2.4rem 0;
  font-size: 2.4rem;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-outline_band {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .p-outline_band {
    margin-bottom: 3rem;
    font-size: 1.6rem;
    padding: 1.2rem 0;
    line-height: 1.3;
  }
}

.p-outline_table {
  max-width: 92rem;
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .p-outline_table {
    overflow-x: scroll;
  }
  .p-outline_table > img {
    width: 74rem;
    max-width: initial;
  }
}

.p-serviceTransport_background {
  background-color: #fff;
  padding: 4rem 0;
}

.p-serviceTransport_box {
  padding-bottom: 4rem;
  background-color: #f5f5f5;
  display: flex;
  padding: 4rem;
  margin: 0 auto 2rem;
}

@media screen and (max-width: 767px) {
  .p-serviceTransport_box {
    display: block;
    padding-bottom: 3rem;
    padding: 4rem 0 0;
  }
}

.p-serviceTransport_boxImage {
  max-width: 43rem;
  width: 100%;
  margin: 0 6rem 0 0;
}

.p-serviceTransport_boxText {
  padding: 2rem;
}

.p-serviceTransport_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100rem;
  width: 100%;
  padding: 4rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-serviceTransport_container {
    display: block;
    margin: 2rem auto;
    padding: 2rem 0;
  }
}

.p-serviceTransport_containerImage {
  max-width: 54rem;
  width: 100%;
  margin: 0 10% 0 0;
}

@media screen and (max-width: 767px) {
  .p-serviceTransport_containerImage {
    width: 90%;
    margin: 0 auto 2rem;
  }
}

.p-serviceTransport_containerText {
  max-width: 100rem;
  width: 100%;
  padding: 0 4rem;
  margin: 0 auto 4rem;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-serviceTransport_containerText {
    margin: 0 auto 2rem;
    padding: 0;
  }
}

.p-serviceTransport_illust {
  max-width: 100rem;
  width: 100%;
  padding: 4rem;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-serviceTransport_illust {
    padding: 2rem 0 0;
  }
}

.p-serviceSeparate_background {
  padding: 4rem 0;
  background-color: #d9ffff;
}

.p-serviceSeparate_background--white {
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .p-serviceSeparate_background--white {
    padding: 2rem 0;
  }
}

.p-serviceSeparate_frame {
  padding: 4rem;
  background-color: #f5f5f5;
}

@media screen and (max-width: 767px) {
  .p-serviceSeparate_frame {
    padding: 2rem;
  }
}

.p-serviceSeparate_text {
  font-size: 1.6rem;
  font-weight: bold;
  color: #18a8a8;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-serviceSeparate_text {
    font-size: 1.4rem;
  }
}

.p-serviceSeparate_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;
  padding: 4rem;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-serviceSeparate_container {
    justify-content: center;
    padding: 2rem 0 0;
    width: 90%;
  }
}

@media screen and (min-width: 768px) {
  .p-serviceSeparate_list:nth-last-child(n + 5) {
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .p-serviceSeparate_list:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.p-serviceSeparate_image {
  max-width: 20rem;
  width: 100%;
  margin: 0 auto 1rem;
}

@media screen and (max-width: 767px) {
  .p-serviceSeparate_image {
    max-width: 100%;
  }
}

.p-serviceCrush_image {
  max-width: 42.9rem;
  width: 100%;
  margin: 0 auto;
}

.p-serviceCrush_item {
  max-width: 27rem;
  width: 100%;
  margin: 0 auto 2rem;
}

@media screen and (max-width: 767px) {
  .p-serviceCrush_item {
    margin: 0 auto 1rem;
  }
}

.p-serviceCrush_background {
  background-color: #fff;
  padding: 4rem 0;
}

.p-serviceCrush_box {
  display: flex;
  align-items: center;
  padding: 4rem;
  background-color: #f5f5f5;
  margin: 0 auto 4rem;
}

@media screen and (max-width: 767px) {
  .p-serviceCrush_box {
    display: block;
    padding: 2rem;
    margin: 0 auto 2rem;
  }
}

.p-serviceCrush_boxImage {
  max-width: 43rem;
  width: 100%;
  margin-right: 6rem;
}

@media screen and (max-width: 767px) {
  .p-serviceCrush_boxImage {
    margin: 0 auto 2rem;
  }
}

.p-serviceCrush_container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100rem;
  padding: 4rem;
  margin: 0 auto;
  justify-content: space-between;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-serviceCrush_container {
    max-width: 67rem;
  }
}

@media screen and (max-width: 767px) {
  .p-serviceCrush_container {
    padding: 2rem 0;
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .p-serviceCrush_list:not(:last-child) {
    margin: 0 auto 2rem;
  }
}

.p-serviceCrush_text {
  font-size: 1.6rem;
  font-weight: bold;
  color: #18a8a8;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-serviceCrush_text {
    font-size: 1.4rem;
  }
}

.p-serviceCrush_empty {
  flex: 1;
}

.p-serviceDredge_background {
  padding: 4rem 0;
  background-color: #d9ffff;
}

.p-serviceDredge_background--white {
  background-color: #fff;
}

.p-serviceDredge_wrap {
  max-width: 100rem;
  width: 100%;
  padding: 4rem;
  box-sizing: border-box;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-serviceDredge_wrap {
    padding: 2rem;
  }
}

.p-serviceDredge_image {
  max-width: 50rem;
  width: 100%;
}

.p-serviceDredge_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem;
  background-color: #f5f5f5;
  max-width: 100rem;
}

@media screen and (max-width: 767px) {
  .p-serviceDredge_box {
    display: block;
    padding: 2rem;
  }
}

.p-serviceDredge_box--white {
  background-color: #fff;
}

.p-serviceDredge_boxImage {
  max-width: 43rem;
  width: 100%;
  margin: 0 6rem 0 0;
}

@media screen and (max-width: 767px) {
  .p-serviceDredge_boxImage {
    margin: 0 auto 2rem;
  }
}

.p-serviceDredge_boxImage--large {
  max-width: 48.5rem;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .p-serviceDredge_boxImage--large {
    margin: 0 auto;
  }
}

.p-serviceDredge_boxText {
  max-width: 43rem;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .p-serviceDredge_boxText {
    margin: 0 auto;
  }
}

.p-serviceDredge_boxText--left {
  margin-right: 6rem;
}

@media screen and (max-width: 767px) {
  .p-serviceDredge_boxText--left {
    margin: 0 auto 2rem;
  }
}

.p-service_inner {
  max-width: 106.4rem;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-service_inner {
    width: 90%;
    padding: 0;
  }
}

.p-service_text {
  font-size: 1.6rem;
  line-height: 1.75;
}

@media screen and (max-width: 767px) {
  .p-service_text {
    font-size: 1.4rem;
  }
}

.p-serviceTitle_container {
  display: flex;
  align-items: center;
  border: 0.2rem solid #18a8a8;
  padding: 1.5rem;
  margin: 0 auto;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .p-serviceTitle_container {
    display: block;
    padding: 1rem;
  }
}

.p-serviceTitle_text {
  background-color: #18a8a8;
  text-align: center;
  color: #fff;
  font-size: 3.6rem;
  padding: 1rem;
  max-width: 24rem;
  width: 100%;
  margin: 0 8% 0 0;
  position: relative;
  box-sizing: border-box;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .p-serviceTitle_text {
    font-size: 2.4rem;
    margin: 0 auto 10%;
    max-width: 100%;
  }
}

.p-serviceTitle_text::after {
  content: '';
  position: absolute;
  width: 0.1rem;
  height: 80%;
  background-color: #18a8a8;
  left: 105%;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .p-serviceTitle_text::after {
    width: 80%;
    height: 0.1rem;
    left: 50%;
    top: 115%;
    transform: translateX(-50%);
  }
}

.p-serviceTitle_small {
  font-size: 1.8rem;
  display: block;
  line-height: 1.3;
}

@media screen and (max-width: 767px) {
  .p-serviceTitle_small {
    font-size: 1.6rem;
  }
}

.p-serviceTitle_description {
  font-size: 2.4rem;
  color: #18a8a8;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .p-serviceTitle_description {
    font-size: 1.4rem;
  }
}

.p-service_subTitle {
  padding: 1rem 4rem;
  border-bottom: 0.1rem solid #18a8a8;
  font-size: 2.4rem;
  color: #18a8a8;
}

@media screen and (max-width: 767px) {
  .p-service_subTitle {
    padding: 1rem 0;
    font-size: 2rem;
  }
}

.p-service_empty {
  width: 20rem;
}

@media screen and (max-width: 767px) {
  .p-service_empty {
    display: none;
  }
}

.p-service_arrow {
  background-color: #18a8a8;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  top: 35%;
  left: 0;
}

@media screen and (max-width: 767px) {
  .p-service_arrow {
    margin: 2rem auto;
  }
}

.p-service_arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1rem;
  height: 1rem;
  border-top: solid 0.2rem #fff;
  border-right: solid 0.2rem #fff;
  transform: translate(-50%, -50%) rotate(45deg);
}

@media screen and (max-width: 767px) {
  .p-service_arrow::after {
    transform: translate(-50%, -50%) rotate(135deg);
  }
}

.p-service_arrow::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6rem;
  height: 0.2rem;
  background-color: #18a8a8;
}

@media screen and (max-width: 767px) {
  .p-service_arrow::before {
    width: 0.2rem;
    height: 6rem;
  }
}

.p-serviceDefinition_wrap {
  border: 0.1rem solid #1aa8a8;
}

.p-serviceDefinition_list {
  display: flex;
  width: 29rem;
}

@media screen and (max-width: 767px) {
  .p-serviceDefinition_list {
    width: 100%;
  }
}

.p-serviceDefinition_list > .p-serviceDefinition_term {
  border-bottom: 0.1rem solid #fff;
}

.p-serviceDefinition_list:last-child > .p-serviceDefinition_term {
  border-bottom: 0.1rem solid #1aa8a8;
}

.p-serviceDefinition_list:not(:last-child) > .p-serviceDefinition_description {
  border-bottom: 0.1rem solid #1aa8a8;
}

.p-serviceDefinition_term {
  width: 45%;
  background-color: #18a8a8;
  color: #fff;
  font-size: 1.8rem;
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;
  display: block;
}

@media screen and (max-width: 767px) {
  .p-serviceDefinition_term {
    font-size: 1.6rem;
  }
}

.p-serviceDefinition_description {
  flex: 1;
  display: block;
  text-align: center;
  background-color: #f5f5f5;
  color: #18a8a8;
  font-size: 1.8rem;
  padding: 1rem;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-serviceDefinition_description {
    font-size: 1.6rem;
  }
}

.p-serviceMenu_background {
  background-color: #f5f5f5;
  padding: 4rem 0;
}

@media screen and (max-width: 767px) {
  .p-serviceMenu_background {
    padding: 3rem 0;
  }
}

.p-serviceMenu_container {
  flex-wrap: wrap;
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .p-serviceMenu_container {
    max-width: 51.2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .p-serviceMenu_list:nth-child(-n + 2) {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .p-serviceMenu_list:not(:last-child) {
    margin: 0 auto 2rem;
  }
}

.p-contact_text {
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  line-height: calc(28 / 16);
}

.p-contact_inner {
  max-width: 106.4rem;
  width: 100%;
  padding: 0 2rem;
  margin: 0 auto;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .p-contact_inner {
    width: 90%;
    padding: 0;
  }
}

.p-contact_wrap {
  max-width: 92rem;
  margin: 0 auto;
  padding-bottom: 12rem;
}

@media screen and (max-width: 767px) {
  .p-contact_wrap {
    padding-bottom: 0;
  }
}

.p-contact_table {
  width: 100%;
  border: 0.1rem solid #18a8a8;
}

.p-contact_phoneBox {
  display: flex;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  .p-contact_phoneBox {
    margin-bottom: 1rem;
    justify-content: center;
  }
}

.p-contact_phoneFrame {
  border-top: 0.1rem solid #18a8a8;
  border-bottom: 0.1rem solid #18a8a8;
  padding: 1.8rem 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media screen and (max-width: 767px) {
  .p-contact_phoneFrame {
    display: block;
    padding: 0.9rem 0;
  }
}

.p-contact_phoneNumber {
  color: #18a8a8;
  font-size: 2.5rem;
  font-weight: bold;
  margin-left: 0.5rem;
}

@media screen and (max-width: 767px) {
  .p-contact_phoneNumber {
    font-size: 2rem;
  }
}

.p-contact_phoneNumber--small {
  font-size: 1.8rem;
}

.p-contact_phoneFax {
  font-size: 1.6rem;
  margin-left: 0.5rem;
}

@media screen and (max-width: 767px) {
  .p-contact_phoneFax {
    font-size: 1.4rem;
    text-align: center;
    margin-left: 0;
  }
}

.p-contact_specify {
  padding-top: 1rem;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-contact_specify {
    font-size: 1.4rem;
  }
}

.p-contact_question {
  color: #18a8a8;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .p-contact_question {
    font-size: 1.6rem;
  }
}

.p-contact_field {
  background-color: #d9ffff;
  padding: 2.4rem;
  border-collapse: collapse;
  vertical-align: middle;
  max-width: 27%;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .p-contact_field {
    border-right: 0.1rem solid #18a8a8;
  }
}

@media screen and (max-width: 767px) {
  .p-contact_field {
    display: block;
    max-width: none;
    width: auto;
    padding: 1.5rem;
  }
}

.p-contact_answer {
  width: 100%;
  height: 4.5rem;
  padding-left: 1rem;
  box-sizing: border-box;
}

.p-contact_answer--small {
  width: 40%;
  padding-left: 1rem;
}

@media screen and (max-width: 767px) {
  .p-contact_answer--small {
    padding-left: 0;
    width: 100%;
  }
}

.p-contact_textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  font-family: inherit;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-contact_textarea {
    font-size: 1.4rem;
  }
}

.p-contact_select {
  appearance: none;
  width: 100%;
  height: 4.5rem;
  padding-left: 0.5rem;
}

.p-contact_selectWrap {
  position: relative;
}

.p-contact_selectTriangle {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.3rem 0.75rem 0 0.75rem;
  border-color: #18a8a8 transparent transparent transparent;
  top: 35%;
  left: 90%;
}

@media screen and (max-width: 767px) {
  .p-contact_selectTriangle {
    border-width: 1.1rem 0.75rem 0 0.75rem;
  }
}

.p-contact_input {
  padding: 4rem;
  border-collapse: collapse;
  border-top: 0.1rem solid #18a8a8;
  vertical-align: middle;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-contact_input {
    font-size: 1.4rem;
    padding: 2rem;
    display: block;
  }
}

.p-contact_require {
  padding: 2rem 0 3rem 1.5rem;
  font-size: 1.6rem;
  line-height: calc(28 / 16);
  letter-spacing: 0.1em;
}

@media screen and (max-width: 767px) {
  .p-contact_require {
    padding: 2rem 0 3rem;
    font-size: 1.4rem;
  }
}

.p-contact_requireText {
  color: #a8184a;
  font-size: 1.4rem;
}

.p-contact_box {
  background-color: #d9ffff;
  padding: 3rem;
  margin: 4rem 0 8rem;
}

@media screen and (max-width: 767px) {
  .p-contact_box {
    margin: 4rem 0;
  }
}

.p-contact_about {
  font-size: 1.6rem;
  text-align: center;
  line-height: 1.75;
  padding: 4rem 0 2.5rem;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 767px) {
  .p-contact_about {
    padding: 2rem 0 1.3rem;
    font-size: 1.4rem;
  }
}

.p-contact_notice {
  width: 80%;
  margin: 0 auto;
  line-height: 1.75;
  padding-bottom: 3rem;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-contact_notice {
    font-size: 1.4rem;
    text-align: justify;
    padding-bottom: 1.5rem;
    width: 100%;
  }
}

.p-contact_terms {
  width: 100%;
  line-height: 1.75;
  box-sizing: border-box;
}

.p-contact_termsWrap {
  padding-bottom: 3rem;
}

.p-contact_radioGroup {
  display: flex;
  padding-left: 3rem;
  flex-wrap: wrap;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-contact_radioGroup {
    flex-direction: column;
    font-size: 1.4rem;
  }
}

.p-contact_radioItem {
  width: 50%;
  padding-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .p-contact_radioItem {
    width: 100%;
  }
}

.p-contact_phantom {
  display: none;
}

.p-contact_check {
  text-align: center;
  margin-left: 2rem;
}

.p-contact_checkInput {
  display: none;
}

.p-contact_title {
  text-align: center;
  font-size: 2.4rem;
  font-weight: bold;
  color: #18a8a8;
  border-bottom: 0.1rem solid #18a8a8;
  padding-bottom: 2rem;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .p-contact_title {
    font-size: 1.6rem;
    padding-bottom: 1rem;
  }
}

.p-contact_titleWrap {
  text-align: center;
  padding-bottom: 2rem;
}

.p-contact_submit {
  font-size: 2.4rem;
  font-weight: bold;
  color: #fff;
  background-color: #18a8a8;
  border: none;
  text-align: center;
  width: 100%;
  padding: 2.2rem;
  max-width: 44rem;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  appearance: none;
  border-radius: 0;
}

@media screen and (max-width: 767px) {
  .p-contact_submit {
    font-size: 1.6rem;
    padding: 1.2rem;
    width: 48%;
  }
}

.p-contact_submit--grey {
  background-color: #acacac;
}

.p-contact_submit--large {
  max-width: 68rem;
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .p-contact_submit--large {
    width: 100%;
  }
}

.p-contact_breadcrumb {
  display: flex;
  width: 100%;
  padding: 2rem 0;
}

.p-contact_breadcrumbItem {
  display: block;
  width: 32%;
  background-color: #d9ffff;
  color: #18a8a8;
  position: relative;
  text-align: center;
  height: 4.6rem;
  line-height: 4.6rem;
  font-size: 1.8rem;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .p-contact_breadcrumbItem {
    font-size: 1.6rem;
  }
}

.p-contact_breadcrumbItem::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 2.3rem solid transparent;
  border-bottom: 2.3rem solid transparent;
  border-left: 3rem solid #d9ffff;
  position: absolute;
  top: 0%;
  left: 100%;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .p-contact_breadcrumbItem::after {
    border-top: 2.3rem solid transparent;
    border-bottom: 2.3rem solid transparent;
    border-left: 1.5rem solid #d9ffff;
  }
}

.p-contact_breadcrumbItem--active {
  background-color: #18a8a8;
  color: #fff;
  z-index: 3;
}

.p-contact_breadcrumbItem--active::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 2.3rem solid transparent;
  border-bottom: 2.3rem solid transparent;
  border-left: 3rem solid #18a8a8;
  position: absolute;
  top: 0%;
  left: 100%;
  z-index: 3;
}

@media screen and (max-width: 767px) {
  .p-contact_breadcrumbItem--active::after {
    border-top: 2.3rem solid transparent;
    border-bottom: 2.3rem solid transparent;
    border-left: 1.5rem solid #18a8a8;
  }
}

.p-contact_thanksTitle {
  font-size: 2.4rem;
  font-weight: bold;
  color: #18a8a8;
  text-align: center;
  padding: 4rem 0;
}

@media screen and (max-width: 767px) {
  .p-contact_thanksTitle {
    padding: 2rem 0;
    font-size: 2.2rem;
  }
}

.p-contact_thanksSentence {
  line-height: 2.5;
  max-width: 69rem;
  margin: 0 auto;
  font-size: 1.6rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .p-contact_thanksSentence {
    font-size: 1.4rem;
  }
}

.p-contact_container {
  display: flex;
  justify-content: space-between;
  padding-top: 6rem;
}

@media screen and (max-width: 767px) {
  .p-contact_container {
    padding-top: 3rem;
  }
}

tr + tr td.p-contact_field {
  border-top: 0.1rem solid #18a8a8;
}

.p-news_inner {
  max-width: 102.4rem;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-news_inner {
    width: 90%;
  }
}

.p-news_wrap {
  padding: 5rem 0 8rem;
}

.p-news_underline {
  padding: 1rem 4rem;
  border-bottom: 0.1rem solid #18a8a8;
}

@media screen and (max-width: 767px) {
  .p-news_underline {
    padding: 1rem;
  }
}

.p-news_ymd {
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-news_ymd {
    font-size: 1.4rem;
  }
}

.p-news_title {
  font-size: 3.2rem;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid #18a8a8;
  margin-bottom: 1rem;
}

@media screen and (max-width: 767px) {
  .p-news_title {
    font-size: 2.4rem;
  }
}

.p-news_button {
  display: block;
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  background-color: #18a8a8;
  border: none;
  text-align: center;
  width: 100%;
  padding: 2.2rem;
  max-width: 44rem;
  cursor: pointer;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-news_button {
    font-size: 1.6rem;
    padding: 1.2rem;
    max-width: 15rem;
  }
}

.p-news_pageNavi {
  padding-top: 3rem;
}

.p-news_list {
  padding-bottom: 3rem;
}

@media screen and (max-width: 767px) {
  .p-news_list {
    padding-bottom: 1rem;
  }
}

.p-news_date {
  font-weight: bold;
  color: #18a8a8;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-news_date {
    font-size: 1.4rem;
  }
}

.p-news_sentence {
  line-height: 1.6;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-news_sentence {
    font-size: 1.4rem;
  }
}

.p-indexAbout_background {
  background-color: #f5f5f5;
  padding-top: 4.5rem;
}

.p-indexAbout_wrap {
  max-width: 102.4rem;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-indexAbout_wrap {
    width: 90%;
  }
}

.p-indexAbout_title {
  font-family: 'Futura';
  font-size: 6rem;
  font-weight: normal;
  padding-bottom: 2.8rem;
  color: #f3ff5c;
}

@media screen and (max-width: 767px) {
  .p-indexAbout_title {
    font-size: 3rem;
    padding-bottom: 1.4rem;
  }
}

.p-indexAbout_box {
  background-color: #18a8a8;
  display: flex;
  padding-left: 4.6rem;
}

@media screen and (max-width: 767px) {
  .p-indexAbout_box {
    display: block;
    padding: 1.8rem;
  }
}

@media screen and (min-width: 768px) {
  .p-indexAbout_sentence {
    padding: 2rem 6rem 4rem 0;
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .p-indexAbout_sentence {
    padding: 0 0 3rem 0;
  }
}

@media screen and (min-width: 768px) {
  .p-indexAbout_item {
    padding: 0 0 4rem 6rem;
  }
}

.p-indexAbout_image {
  max-width: 35.9rem;
  padding-bottom: 4rem;
}

.p-indexAbout_text {
  line-height: 1.6;
  color: #fff;
  letter-spacing: 0.1em;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-indexAbout_text {
    font-size: 1.4rem;
  }
}

.p-indexAbout_subTitle {
  font-size: 2rem;
  position: relative;
  padding-bottom: 3rem;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .p-indexAbout_subTitle {
    font-size: 1.6rem;
  }
}

.p-indexAbout_subTitle::after {
  position: absolute;
  content: '';
  width: 110%;
  height: 0.2rem;
  background-color: #f3ff5c;
  left: -4.6rem;
  top: 3.2rem;
}

@media screen and (max-width: 767px) {
  .p-indexAbout_subTitle::after {
    width: 100%;
    left: 0;
  }
}

.p-indexAbout_button {
  width: 100%;
  height: 5rem;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #18a8a8;
}

.p-indexAbout_button::before {
  content: '';
  background-color: #fff;
  width: 100%;
  height: 5rem;
  transition: all 0.1s linear;
  transform: translateX(50%);
  position: absolute;
}

.p-indexAbout_button:hover::before {
  transform: translateX(0%);
  width: 100%;
  height: 5rem;
}

.p-indexAbout_button:hover .p-indexAbout_buttonText {
  position: absolute;
  color: #18a8a8;
  transform: translateX(50%);
}

.p-indexAbout_button:hover .p-indexAbout_buttonArrow {
  position: absolute;
  color: #fff;
  transform: translateX(-50%) rotate(45deg);
}

.p-indexAbout_buttonText {
  position: absolute;
  left: 7%;
  color: #fff;
  z-index: 2;
  transition: all 0.1s linear;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 767px) {
  .p-indexAbout_buttonText {
    left: 3%;
    font-size: 1.4rem;
  }
}

.p-indexAbout_buttonArrow {
  z-index: 2;
  transition: all 0.1s linear;
  position: absolute;
  left: 73%;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-top: solid 0.3rem #18a8a8;
  border-right: solid 0.3rem #18a8a8;
  transform: rotate(45deg);
}

.p-indexService_wrap {
  max-width: 102.4rem;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-indexService_wrap {
    width: 90%;
  }
}

.p-indexService_title {
  font-family: 'Futura';
  font-size: 6rem;
  font-weight: normal;
  padding-bottom: 2.8rem;
  color: #18a8a8;
}

@media screen and (max-width: 767px) {
  .p-indexService_title {
    font-size: 3rem;
    padding-bottom: 1.4rem;
  }
}

.p-indexService_subTitle {
  padding-bottom: 5rem;
  position: relative;
  color: #18a8a8;
  font-size: 1.8rem;
}

@media screen and (max-width: 767px) {
  .p-indexService_subTitle {
    padding-bottom: 3rem;
  }
}

.p-indexService_subTitle::after {
  position: absolute;
  content: '';
  width: 28%;
  height: 0.2rem;
  background-color: #18a8a8;
  top: 3.2rem;
  left: 0;
}

@media screen and (max-width: 767px) {
  .p-indexService_subTitle::after {
    width: 90%;
  }
}

.p-indexService_text {
  line-height: 1.6;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
}

.p-indexService_sentence {
  padding: 6rem 0 4rem;
}

@media screen and (max-width: 767px) {
  .p-indexService_sentence {
    padding: 4rem 0 2rem;
  }
}

.p-indexServiceProcess_container {
  display: flex;
  justify-content: flex-end;
  max-width: 128rem;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 767px) {
  .p-indexServiceProcess_container {
    flex-wrap: wrap;
  }
}

.p-indexServiceProcess_container::before {
  content: '';
  position: absolute;
  background-color: #18a8a8;
  width: 100vw;
  height: 45rem;
  bottom: -8rem;
  right: 0%;
}

@media screen and (max-width: 767px) {
  .p-indexServiceProcess_container::before {
    bottom: -2rem;
  }
}

.p-indexServiceProcess_list {
  max-width: 28.8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

@media screen and (max-width: 767px) {
  .p-indexServiceProcess_list {
    width: 49%;
    padding-bottom: 2rem;
  }
}

.p-indexServiceProcess_image {
  max-width: 28.8rem;
  width: 100%;
}

.p-indexServiceProcess_box {
  padding: 2rem;
  background-color: #fff;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .p-indexServiceProcess_box {
    padding: 1rem;
  }
}

.p-indexServiceProcess_box--gray {
  background-color: #f5f5f5;
}

.p-indexServiceProcess_text {
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .p-indexServiceProcess_text {
    font-size: 1.2rem;
  }
}

.p-indexServiceProcess_title {
  color: #008b8b;
  font-size: 3.2rem;
  text-align: center;
  padding: 0 0 2rem;
}

@media screen and (max-width: 767px) {
  .p-indexServiceProcess_title {
    font-size: 1.8rem;
    padding: 0 0 1rem;
  }
}

.p-indexServiceProcess_title--small {
  font-size: 2rem;
}

@media screen and (max-width: 767px) {
  .p-indexServiceProcess_title--small {
    font-size: 1.1rem;
  }
}

.p-indexServiceOther_background {
  background-color: #f5f5f5;
  padding: 8rem 0;
  margin: 8rem 0 0 0;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_background {
    padding: 2rem 0;
    margin: 2rem 0 0 0;
  }
}

.p-indexServiceOther_container {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_container {
    flex-wrap: wrap;
  }
}

.p-indexServiceOther_item {
  max-width: 23rem;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_item {
    width: 50%;
  }
}

.p-indexServiceOther_image {
  max-width: 23rem;
  width: 100%;
}

.p-indexServiceOther_button {
  max-width: 28.7rem;
  width: 100%;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_button {
    width: 50%;
  }
}

.p-indexServiceOther_buttonImage {
  max-width: 9.5rem;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_buttonImage {
    max-width: 5.7rem;
  }
}

.p-indexServiceOther_buttonBox {
  padding: 0 15%;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_buttonBox {
    padding: 0 1.3rem;
  }
}

.p-indexServiceOther_buttonTitle {
  font-size: 2.8rem;
  line-height: 1.5;
  padding-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_buttonTitle {
    font-size: 1.6rem;
  }
}

.p-indexServiceOther_buttonSubTitle {
  font-size: 1.6rem;
  padding-bottom: 2rem;
  position: relative;
  letter-spacing: 0;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_buttonSubTitle {
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.3;
    padding-bottom: 1rem;
  }
}

.p-indexServiceOther_buttonSubTitle::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 0.1rem;
  background-color: #000;
  left: 0;
  top: 3.2rem;
}

.p-indexServiceOther_buttonLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-indexServiceOther_buttonLinkText {
  color: #008b8b;
  font-size: 1.6rem;
  letter-spacing: 0;
  border-bottom: 0.1rem solid #008b8b;
  padding-bottom: 0.1rem;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_buttonLinkText {
    font-size: 1.2rem;
  }
}

.p-indexServiceOther_buttonLinkImage {
  max-width: 2.2rem;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_buttonLinkImage {
    max-width: 1.8rem;
  }
}

.p-indexServiceOther_title {
  background-color: #18a8a8;
  font-size: 1.8rem;
  color: #f5f5f5;
  text-align: center;
  letter-spacing: 0;
  padding: 4rem 2.5rem;
}

@media screen and (max-width: 767px) {
  .p-indexServiceOther_title {
    font-size: 1.2rem;
    padding: 3rem 1rem;
  }
}

@media screen and (min-width: 768px) {
  .p-indexInfo_wrap {
    max-width: 102.4rem;
    margin: 0 auto;
  }
}

.p-indexInfo_container {
  display: flex;
}

@media screen and (max-width: 767px) {
  .p-indexInfo_container {
    display: block;
  }
}

.p-indexInfoContacts_background {
  position: relative;
}

@media screen and (max-width: 767px) {
  .p-indexInfoContacts_background {
    padding: 0 0 8rem 10%;
  }
}

.p-indexInfoContacts_background::before {
  content: '';
  position: absolute;
  background-color: #18a8a8;
  width: 100vw;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.p-indexInfoContacts_image {
  max-width: 34.4rem;
  width: 100%;
  padding-bottom: 3rem;
}

.p-indexInfoContacts_title {
  font-family: 'Futura';
  font-size: 6rem;
  font-weight: normal;
  padding-bottom: 2.8rem;
  color: #f3ff5c;
}

@media screen and (max-width: 767px) {
  .p-indexInfoContacts_title {
    font-size: 3rem;
    padding-bottom: 1.4rem;
  }
}

.p-indexInfoContacts_subTitle {
  color: #fff;
  font-size: 1.8rem;
  padding-bottom: 5rem;
}

@media screen and (max-width: 767px) {
  .p-indexInfoContacts_subTitle {
    padding-bottom: 2.5rem;
  }
}

.p-indexInfoContacts_button {
  width: 90%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.1s linear;
}

@media screen and (min-width: 768px) {
  .p-indexInfoContacts_button {
    margin-bottom: 8rem;
  }
}

.p-indexInfoContacts_button:hover {
  width: 100%;
}

.p-indexInfoContacts_button:hover .p-indexInfoContacts_buttonText {
  transform: translateX(20%);
}

.p-indexInfoContacts_button:hover .p-indexInfoContacts_buttonArrow {
  transform: translateX(120%) rotate(45deg);
}

.p-indexInfoContacts_buttonText {
  font-size: 1.6rem;
  color: #18a8a8;
  padding: 2.7rem 3.2rem;
  transition: transform 0.1s linear;
}

@media screen and (max-width: 767px) {
  .p-indexInfoContacts_buttonText {
    font-size: 1.4rem;
  }
}

.p-indexInfoContacts_buttonArrow {
  transition: transform 0.1s linear;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border-top: solid 0.3rem #18a8a8;
  border-right: solid 0.3rem #18a8a8;
  transform: rotate(45deg);
}

.p-indexInfoNews_background {
  padding: 2rem 0 0 4rem;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .p-indexInfoNews_background {
    display: none;
  }
}

.p-indexInfoNews_container {
  display: flex;
}

.p-indexInfoNews_title {
  font-family: 'Futura';
  font-size: 6rem;
  font-weight: normal;
  padding-bottom: 2.8rem;
  color: #18a8a8;
}

.p-indexInfoNews_subTitle {
  font-size: 1.8rem;
  color: #18a8a8;
}

.p-indexInfoNews_box {
  flex: 1;
  position: relative;
  padding-left: 4rem;
  margin-left: 4rem;
  border-left: 0.1rem solid #18a8a8;
}

.p-indexInfoNews_list {
  padding-bottom: 3rem;
}

.p-indexInfoNews_date {
  font-weight: bold;
  color: #18a8a8;
  padding-bottom: 1.5rem;
  border-bottom: 0.1rem solid;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.p-indexInfoNews_text {
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 0.1em;
}

.p-indexInfoNews_button {
  max-width: 23rem;
  margin-left: auto;
}

.p-indexHero {
  display: flex;
  align-items: flex-end;
}

.p-indexHero_box {
  padding: 2.2rem 1.5rem;
  color: #fff;
}

.p-indexHero_title {
  font-size: 1.7rem;
  padding-bottom: 3.5rem;
  position: relative;
}

.p-indexHero_title::after {
  position: absolute;
  content: '';
  width: 20%;
  height: 0.1rem;
  background-color: #fff;
  left: 0;
  top: 65%;
}

.p-indexHero_sentence {
  font-size: 1.4rem;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .p-indexHero_sentence {
    font-size: 1.4rem;
  }
}

.p-index_wrap {
  max-width: 102.4rem;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-index_wrap {
    width: 90%;
  }
}

.u-pb-10 {
  padding-bottom: 1rem;
}

.u-pb-20 {
  padding-bottom: 2rem;
}

.u-pb-40 {
  padding-bottom: 4rem;
}

.u-pb-50 {
  padding-bottom: 5rem;
}

.u-pb-27 {
  padding-bottom: 2.7rem;
}

@media screen and (max-width: 767px) {
  .u-sp-image {
    padding-bottom: 1.5rem;
  }
}

.u-bg_lightgray {
  background-color: #f5f5f5;
}

.u-bg_skyblue {
  background-color: #d9ffff;
}

.u-bg_white {
  background-color: #fff;
}

.u-c_lightgray {
  color: #f5f5f5;
}

.mwform-radio-field-text {
  position: relative;
  cursor: pointer;
  padding-right: 5rem;
  display: block;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .mwform-radio-field-text {
    font-size: 1.4rem;
  }
}

.mwform-radio-field-text::before {
  content: '';
  display: block;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  background-color: #fff;
  border: 0.1rem solid #000;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  left: -3rem;
}

.mwform-radio-field-text::after {
  content: '';
  display: block;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  background-color: #18a8a8;
  border-radius: 50%;
  opacity: 0;
  width: 1.6rem;
  height: 1.6rem;
  left: -2.7rem;
}

.mwform-checkbox-field-text {
  position: relative;
  display: inline-block;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .mwform-checkbox-field-text {
    font-size: 1.2rem;
  }
}

.mwform-checkbox-field-text::before {
  content: '';
  position: absolute;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 3rem;
  height: 3rem;
  border: 0.1rem solid #18a8a8;
  background-color: #fff;
  transform: translate(-120%, -50%);
  top: 50%;
}

@media screen and (max-width: 767px) {
  .mwform-checkbox-field-text::before {
    width: 2rem;
    height: 2rem;
  }
}

.mwform-checkbox-field-text::after {
  content: '';
  position: absolute;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 1.5rem;
  height: 1rem;
  left: -1rem;
  top: 50%;
  -webkit-transform: rotate(-45deg);
  transform: translate(-120%, -50%) rotate(-45deg);
  border-left: 0.4rem solid #18a8a8;
  border-bottom: 0.4rem solid #18a8a8;
  border-right-color: #18a8a8;
  border-top-color: #18a8a8;
  opacity: 0;
}

@media screen and (max-width: 767px) {
  .mwform-checkbox-field-text::after {
    border-left: 0.3rem solid #18a8a8;
    border-bottom: 0.3rem solid #18a8a8;
    left: -0.3rem;
  }
}

input[value='男性']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

input[value='女性']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

input[value='検索サイトで']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

input[value='新聞・雑誌等で']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

input[value='知人から']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

input[value='その他']:checked + .mwform-radio-field-text::after {
  opacity: 1;
}

#agree-1:checked + .mwform-checkbox-field-text::after {
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .mw_wp_form_confirm {
    padding-bottom: 3rem;
  }
}

.mw_wp_form_confirm .p-contact_radioGroup {
  padding-left: 0;
}

.mw_wp_form_confirm .p-contact_selectTriangle {
  display: none;
}

.mw_wp_form_confirm .p-contact_specify {
  display: none;
}

.mw_wp_form_confirm .p-contact_radioItem {
  width: auto;
  padding-bottom: 0;
}

.mw_wp_form_confirm .p-contact_selectWrap {
  padding-left: 0;
}

.mw_wp_form_confirm .p-contact_field > .p-contact_question > .p-contact_requireText {
  display: none;
}

.mw_wp_form_confirm .p-contact_boxWrap {
  display: none;
}

.mw_wp_form_input .u-display {
  display: none;
}

.mw_wp_form_input .error {
  padding-top: 1rem;
}

.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0;
}

.p-contact_radioGroup.u-referer span:nth-child(1) {
  width: 50%;
  padding-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .p-contact_radioGroup.u-referer span:nth-child(1) {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .p-contact_radioGroup.u-referer > span:nth-child(2) {
    padding-bottom: 2rem;
  }
}

.p-contact_radioGroup.u-referer span:nth-child(3) {
  width: 50%;
  padding-bottom: 2rem;
}

@media screen and (max-width: 767px) {
  .p-contact_radioGroup.u-referer span:nth-child(3) {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .p-contact_input .p-contact_radioItem--small span:nth-child(1) {
    padding-bottom: 2rem;
  }
}

.mw_wp_form .error {
  font-size: 1.4rem;
}

@media screen and (max-width: 767px) {
  .mw_wp_form .error {
    font-size: 1.2rem;
  }
}

.wp-pagenavi {
  text-align: center;
}

.wp-pagenavi a,
.wp-pagenavi span {
  text-decoration: none;
  border: 0.1rem solid #18a8a8;
  padding: 1.5rem 2rem;
  margin: 0.2rem;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .wp-pagenavi a,
  .wp-pagenavi span {
    padding: 0.8rem 1.2rem;
  }
}

.wp-pagenavi a:hover,
.wp-pagenavi span.current {
  color: #fff;
  background-color: #18a8a8;
  border-color: #18a8a8;
}

.p-detail {
  padding-bottom: 6rem;
}

.p-detail h1 {
  color: #fff;
  background-color: #18a8a8;
  padding: 0.5rem 2rem;
}

@media screen and (max-width: 767px) {
  .p-detail h1 {
    padding: 0.5rem 1rem;
  }
}

.p-detail h2,
.p-detail h3,
.p-detail h4,
.p-detail h5,
.p-detail h6 {
  display: inline-block;
  color: #18a8a8;
  border-bottom: 0.3rem solid #18a8a8;
  padding-bottom: 0.5rem;
}

.p-detail h1 {
  font-size: calc(2.6rem - 0.2 * 1rem);
  margin-bottom: 2.4rem;
}

.p-detail h2 {
  font-size: calc(2.6rem - 0.2 * 2rem);
  margin-bottom: 2.4rem;
}

.p-detail h3 {
  font-size: calc(2.6rem - 0.2 * 3rem);
  margin-bottom: 2.4rem;
}

.p-detail h4 {
  font-size: calc(2.6rem - 0.2 * 4rem);
  margin-bottom: 2.4rem;
}

.p-detail h5 {
  font-size: calc(2.6rem - 0.2 * 5rem);
  margin-bottom: 2.4rem;
}

.p-detail h6 {
  font-size: calc(2.6rem - 0.2 * 6rem);
  margin-bottom: 2.4rem;
}

.p-detail p {
  line-height: 2;
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-detail p {
    font-size: 1.4rem;
  }
}

.p-detail a {
  color: #18a8a8;
  font-size: 1.6rem;
  border-bottom: solid 0.1rem #18a8a8;
}

@media screen and (max-width: 767px) {
  .p-detail a {
    font-size: 1.4rem;
  }
}

.p-detail pre {
  font-size: 1.6rem;
}

@media screen and (max-width: 767px) {
  .p-detail pre {
    font-size: 1.4rem;
  }
}

.u-carousel {
  padding-bottom: 5rem;
  background-color: #18a8a8;
  flex: 1;
}

@media screen and (max-width: 767px) {
  .u-carousel {
    padding-bottom: 3.5rem;
  }
}

@media screen and (min-width: 768px) {
  .u-pagination {
    bottom: 2rem !important;
    width: auto !important;
    left: 10% !important;
  }
}

.u-pagination span {
  border-radius: 0%;
  width: 6rem;
  height: 0.3rem;
}

.swiper-slide img {
  width: 100%;
}

.swiper-pagination-bullet {
  background-color: #fff !important;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #f3ff5c !important;
}

.u-sup {
  vertical-align: text-top;
  font-size: small;
}

.u-letter {
  letter-spacing: 0em;
}

.u-text-green {
  color: #18a8a8;
}

.u-lh-175 {
  line-height: 1.75;
}

.u-arrow-left {
  left: 11%;
}

@media screen and (max-width: 767px) {
  .u-arrow-left {
    left: 5%;
  }
}

.u-mt-2-center {
  margin: 2rem auto 0;
}

@media screen and (max-width: 767px) {
  .u-mt-2-center {
    margin: 1rem auto 3rem;
  }
}
