.p-index {
  &About {
    &_background {
      background-color: #f5f5f5;
      padding-top: 4.5rem;
    }
    &_wrap {
      max-width: 102.4rem;
      margin: 0 auto;
      @include sp {
        width: 90%;
      }
    }
    &_title {
      font-family: 'Futura';
      font-size: 6rem;
      font-weight: normal;
      padding-bottom: 2.8rem;
      color: #f3ff5c;
      @include sp {
        font-size: 3rem;
        padding-bottom: 1.4rem;
      }
    }
    &_box {
      background-color: #18a8a8;
      display: flex;
      padding-left: 4.6rem;
      @include sp {
        display: block;
        padding: 1.8rem;
      }
    }
    &_sentence {
      @include pc {
        padding: 2rem 6rem 4rem 0;
        flex: 1;
      }
      @include sp {
        padding: 0 0 3rem 0;
      }
    }
    &_item {
      @include pc {
        padding: 0 0 4rem 6rem;
      }
    }
    &_image {
      max-width: 35.9rem;
      padding-bottom: 4rem;
    }
    &_text {
      line-height: 1.6;
      color: #fff;
      letter-spacing: 0.1em;
      font-size: 1.6rem;
      @include sp {
        font-size: 1.4rem;
      }
    }
    &_subTitle {
      font-size: 2rem;
      position: relative;
      padding-bottom: 3rem;
      color: #fff;
      @include sp {
        font-size: 1.6rem;
      }
      &::after {
        position: absolute;
        content: '';
        width: 110%;
        height: 0.2rem;
        background-color: #f3ff5c;
        left: -4.6rem;
        top: 3.2rem;
        @include sp {
          width: 100%;
          left: 0;
        }
      }
    }
    &_button {
      width: 100%;
      height: 5rem;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      background-color: #18a8a8;
      &::before {
        content: '';
        background-color: #fff;
        width: 100%;
        height: 5rem;
        transition: all 0.1s linear;
        transform: translateX(50%);
        position: absolute;
      }
      &:hover {
        &::before {
          transform: translateX(0%);
          width: 100%;
          height: 5rem;
        }
        .p-indexAbout_buttonText {
          position: absolute;
          color: #18a8a8;
          transform: translateX(50%);
        }
        .p-indexAbout_buttonArrow {
          position: absolute;
          color: #fff;
          transform: translateX(-50%) rotate(45deg);
        }
      }
      &Text {
        position: absolute;
        left: 7%;
        color: #fff;
        z-index: 2;
        transition: all 0.1s linear;
        font-size: 1.6rem;
        letter-spacing: 0.1em;
        @include sp {
          left: 3%;
          font-size: 1.4rem;
        }
      }
      &Arrow {
        z-index: 2;
        transition: all 0.1s linear;
        position: absolute;
        left: 73%;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        border-top: solid 0.3rem #18a8a8;
        border-right: solid 0.3rem #18a8a8;
        transform: rotate(45deg);
      }
    }
  }
}
