.p-index {
  &Service {
    &_wrap {
      max-width: 102.4rem;
      margin: 0 auto;
      @include sp {
        width: 90%;
      }
    }
    &_title {
      font-family: 'Futura';
      font-size: 6rem;
      font-weight: normal;
      padding-bottom: 2.8rem;
      color: #18a8a8;
      @include sp {
        font-size: 3rem;
        padding-bottom: 1.4rem;
      }
    }
    &_subTitle {
      padding-bottom: 5rem;
      position: relative;
      color: #18a8a8;
      font-size: 1.8rem;
      @include sp {
        padding-bottom: 3rem;
      }
      &::after {
        position: absolute;
        content: '';
        width: 28%;
        height: 0.2rem;
        background-color: #18a8a8;
        top: 3.2rem;
        left: 0;
        @include sp {
          width: 90%;
        }
      }
    }
    &_text {
      line-height: 1.6;
      font-size: 1.6rem;
      letter-spacing: 0.1em;
    }
    &_sentence {
      padding: 6rem 0 4rem;
      @include sp {
        padding: 4rem 0 2rem;
      }
    }
    &Process {
      &_container {
        display: flex;
        justify-content: flex-end;
        max-width: 128rem;
        margin: 0 auto;
        position: relative;
        @include sp {
          flex-wrap: wrap;
        }
        &::before {
          content: '';
          position: absolute;
          background-color: #18a8a8;
          width: 100vw;
          height: 45rem;
          bottom: -8rem;
          right: 0%;
          @include sp {
            bottom: -2rem;
          }
        }
      }
      &_list {
        max-width: 28.8rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        @include sp {
          width: 49%;
          padding-bottom: 2rem;
        }
      }
      &_image {
        max-width: 28.8rem;
        width: 100%;
      }
      &_box {
        padding: 2rem;
        background-color: #fff;
        flex: 1;
        @include sp {
          padding: 1rem;
        }
        &--gray {
          background-color: #f5f5f5;
        }
      }
      &_text {
        font-size: 1.6rem;
        letter-spacing: 0.1em;
        line-height: 1.6;
        @include sp {
          font-size: 1.2rem;
        }
      }
      &_title {
        color: #008b8b;
        font-size: 3.2rem;
        text-align: center;
        padding: 0 0 2rem;
        @include sp {
          font-size: 1.8rem;
          padding: 0 0 1rem;
        }
        &--small {
          font-size: 2rem;
          @include sp {
            font-size: 1.1rem;
          }
        }
      }
    }
    &Other {
      &_background {
        background-color: #f5f5f5;
        padding: 8rem 0;
        margin: 8rem 0 0 0;
        @include sp {
          padding: 2rem 0;
          margin: 2rem 0 0 0;
        }
      }
      &_container {
        display: flex;
        justify-content: space-between;
        @include sp {
          flex-wrap: wrap;
        }
      }
      &_item {
        max-width: 23rem;
        width: 100%;
        @include sp {
          width: 50%;
        }
      }
      &_image {
        max-width: 23rem;
        width: 100%;
      }
      &_button {
        max-width: 28.7rem;
        width: 100%;
        background-color: #fff;
        @include sp {
          width: 50%;
        }
        &Image {
          max-width: 9.5rem;
          width: 100%;
          margin: 0 auto;
          @include sp {
            max-width: 5.7rem;
          }
        }
        &Box {
          padding: 0 15%;
          @include sp {
            padding: 0 1.3rem;
          }
        }
        &Title {
          font-size: 2.8rem;
          line-height: 1.5;
          padding-bottom: 2rem;
          @include sp {
            font-size: 1.6rem;
          }
        }
        &SubTitle {
          font-size: 1.6rem;
          padding-bottom: 2rem;
          position: relative;
          letter-spacing: 0;
          @include sp {
            font-size: 1.1rem;
            text-align: center;
            line-height: 1.3;
            padding-bottom: 1rem;
          }
          &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 0.1rem;
            background-color: #000;
            left: 0;
            top: 3.2rem;
          }
        }
        &Link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &Text {
            color: #008b8b;
            font-size: 1.6rem;
            letter-spacing: 0;
            border-bottom: 0.1rem solid #008b8b;
            padding-bottom: 0.1rem;
            line-height: 1.2;
            @include sp {
              font-size: 1.2rem;
            }
          }
          &Image {
            max-width: 2.2rem;
            width: 100%;
            @include sp {
              max-width: 1.8rem;
            }
          }
        }
      }
      &_title {
        background-color: #18a8a8;
        font-size: 1.8rem;
        color: #f5f5f5;
        text-align: center;
        letter-spacing: 0;
        padding: 4rem 2.5rem;
        @include sp {
          font-size: 1.2rem;
          padding: 3rem 1rem;
        }
      }
    }
  }
}
