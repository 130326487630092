.p-index {
  &Hero {
    display: flex;
    align-items: flex-end;
    &_box {
      padding: 2.2rem 1.5rem;
      color: #fff;
    }
    &_title {
      font-size: 1.7rem;
      padding-bottom: 3.5rem;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        width: 20%;
        height: 0.1rem;
        background-color: #fff;
        left: 0;
        top: 65%;
      }
    }
    &_sentence {
      font-size: 1.4rem;
      line-height: 1.6;
      @include sp {
        font-size: 1.4rem;
      }
    }
  }
}
