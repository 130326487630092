.p-about {
  &_inner {
    max-width: 106.4rem;
    width: 100%;
    padding: 0 2rem;
    margin: 0 auto;
    box-sizing: border-box;
    @include sp {
      width: 90%;
      padding: 0;
    }
  }
  &_top {
    padding-bottom: 5rem;
    @include sp {
      padding-bottom: 2.5rem;
    }
  }
  &_illust {
    max-width: 94.5rem;
    width: 100%;
    margin: 0 auto;
  }
  &_access {
    padding-bottom: 6rem;
  }
  &_greet {
    display: flex;
    padding-bottom: 4rem;
    @include sp {
      display: block;
      padding-bottom: 2rem;
    }
    &Box {
      padding: 4.5rem 0 4.5rem 4.5rem;
      @include sp {
        padding: 1rem 0 0;
      }
    }
    &Title {
      font-size: 2.4rem;
      color: #18a8a8;
      font-weight: bold;
      line-height: 1.5;
      border-bottom: solid 0.1rem #18abab;
      padding-bottom: 2.4rem;
      margin-bottom: 2.4rem;
      @include sp {
        font-size: 2rem;
        padding-bottom: 1.2rem;
        margin-bottom: 1.2rem;
      }
    }
    &Image {
      max-width: 45.6rem;
      width: 100%;
      margin: 0 auto;
    }
  }
  &_title {
    padding-bottom: 4rem;
  }
  &Menu {
    &_wrap {
      max-width: 77rem;
      margin: 0 auto;
      padding: 8rem 0 10rem;
      @include sp {
        padding: 4rem 0;
      }
    }
    &_list {
      &:not(:last-child) {
        @include sp {
          margin: 0 auto 2rem;
        }
      }
    }
  }
  &_person {
    color: #18a8a8;
    font-size: 2rem;
    text-align: right;
    &--job {
      font-size: 1.7rem;
    }
  }
  &_text {
    font-size: 1.6rem;
    line-height: 1.75;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_guide {
    padding: 3.5rem 0;
  }
  &_container {
    display: flex;
    @include sp {
      display: block;
    }
  }
  &_image {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0 4rem;
    @include sp {
      width: 100%;
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
    }
  }
  &_address {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    & + & {
      border-top: solid 0.1rem #18abab;
      padding-top: 2.4rem;
      margin-top: 2.4rem;
    }
    @include sp {
      display: block;
    }
    &Text {
      font-size: 1.6rem;
      @include sp {
        padding-bottom: 1.5rem;
        font-size: 1.4rem;
      }
    }
  }
  &_item {
    width: 100%;
    &--left {
      padding-right: 2.5rem;
      @include sp {
        padding-right: 0;
      }
    }
    &--right {
      padding-left: 2.5rem;
      @include sp {
        padding-left: 0;
      }
    }
  }
  &_phone {
    color: #18a8a8;
    font-weight: bold;
    font-size: 1.8rem;
    padding-bottom: 4rem;
    @include sp {
      font-size: 1.6rem;
      padding-bottom: 2rem;
    }
  }
  &_wrap {
    padding: 4rem;
    @include sp {
      padding: 2rem 0;
    }
  }
  &_facility {
    border-bottom: #18a8a8 solid 0.1rem;
    padding-bottom: 1.5rem;
    font-size: 1.8rem;
    @include sp {
      font-size: 1.5rem;
      padding-bottom: 0.8rem;
    }
  }
  &Definition {
    &_list {
      display: flex;
      width: 100%;
      border-left: 0.1rem solid #707070;
      border-right: 0.1rem solid #707070;
      &:first-of-type {
        border-top: 0.1rem solid #707070;
      }
      & > .p-aboutDefinition_term {
        border-bottom: 0.1rem solid #fff;
      }
      &:last-child > .p-aboutDefinition_term {
        border-bottom: 0.1rem solid #707070;
      }
      @include sp {
        flex-direction: column;
      }
    }
    &_term {
      font-size: 1.6rem;
      font-weight: bold;
      background-color: #18a8a8;
      color: #fff;
      padding: 1rem;
      width: 16%;
      letter-spacing: 0.1em;
      display: flex;
      align-items: center;
      justify-content: center;
      @include sp {
        font-size: 1.4rem;
        width: 100%;
        padding: 1rem 0;
      }
    }
    &_description {
      font-size: 1.6rem;
      padding: 2rem;
      line-height: calc(28 / 16);
      letter-spacing: 0.1em;
      border-bottom: 0.1rem solid #707070;
      flex: 1;
      @include sp {
        font-size: 1.4rem;
        padding: 1rem;
      }
    }
  }
  &_subTitle {
    padding: 1rem 4rem;
    border-bottom: 0.1rem solid #18a8a8;
    font-size: 2.4rem;
    color: #18a8a8;
  }
  &_award {
    max-width: 49.2rem;
    width: 100%;
    margin: 0 auto;
    padding: 4rem 0;
    @include sp {
      padding: 2rem 0rem;
    }
    &Image {
      padding-bottom: 2rem;
      @include sp {
        padding-bottom: 1rem;
      }
    }
  }
  &_button {
    background-color: #18a8a8;
    color: #fff;
    text-align: center;
    border-radius: 5rem;
    font-weight: bold;
    font-size: 1.6rem;
    padding: 0.5rem 2.5rem;
    display: block;
    @include sp {
      font-size: 1.4rem;
      width: 70%;
      margin: 0 auto;
    }
  }
  &_menu {
    @include sp {
      flex-direction: column;
      align-items: center;
    }
    &Item {
      @include sp {
        margin-bottom: 2rem;
      }
    }
  }
  &_place {
    max-width: 30rem;
    width: 100%;
    margin: 0 auto;
  }
  &_iframe {
    width: 100%;
    height: 100%;
    border: 0;
    @include sp {
      height: 90%;
    }
    &Wrap {
      width: 100%;
      @include pc {
        max-width: 62rem;
      }
    }
  }
}
