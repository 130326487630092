.p-detail {
  padding-bottom: 6rem;
  h1 {
    color: #fff;
    background-color: #18a8a8;
    padding: 0.5rem 2rem;
    @include sp {
      padding: 0.5rem 1rem;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: inline-block;
    color: #18a8a8;
    border-bottom: 0.3rem solid #18a8a8;
    padding-bottom: 0.5rem;
  }
  // h1〜h6タグの文字サイズ
  @for $i from 1 through 6 {
    h#{$i} {
      font-size: calc(2.6rem - 0.2 * #{$i}rem);
      margin-bottom: 2.4rem;
    }
  }
  p {
    line-height: 2;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
  }
  a {
    color: #18a8a8;
    font-size: 1.6rem;
    border-bottom: solid 0.1rem #18a8a8;
    @include sp {
      font-size: 1.4rem;
    }
  }
  pre {
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
  }
}
