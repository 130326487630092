.u-sup {
  vertical-align: text-top;
  font-size: small;
}

.u-letter {
  letter-spacing: 0em;
}

.u-text-green {
  color: #18a8a8;
}

.u-lh-175 {
  line-height: 1.75;
}
