.l-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 65535;
  &_bg {
    background-color: #008b8b;
    color: #fff;
  }
  &_logo {
    margin: 0 0 0 3rem;
    @include sp {
      margin: 0;
    }
  }
  &_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 14rem;
    @include sp {
      height: 7rem;
      padding: 0 1.8rem;
      position: relative;
    }
  }
  &_nav {
    display: flex;
    text-align: center;
    align-items: center;
    height: 14rem;
    justify-content: flex-end;
    @include sp {
      width: 100%;
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      height: 0;
    }
    &Item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #008b8b;
      position: relative;
      @include pc {
        max-width: 13rem;
        border-left: #fff solid 0.1rem;
        height: 5rem;
        padding: 0 0.3rem;
      }
      @include header(1529px) {
        max-width: 10rem;
      }
      @include sp {
        width: auto;
        height: 0;
        overflow: hidden;
        transition: all 0.5s;
        position: relative;
      }
    }
    &Contact {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      background-color: #f3ff5c;
      position: relative;
      &:hover {
        @include pc {
          background-color: #f5f5f5;
        }
      }
      @include pc {
        max-width: 13rem;
        border-left: #fff solid 0.1rem;
        padding: 0 0.3rem;
        border: none;
        height: 100%;
      }
      @include header(1529px) {
        max-width: 10rem;
      }
      @include sp {
        width: auto;
        height: 0;
        overflow: hidden;
        transition: all 0.5s;
        position: relative;
      }
    }
    &Link {
      font-size: 1.6rem;
      color: #fff;
      position: relative;
      line-height: 1.5;
      @include header(1529px) {
        font-size: 1.2rem;
      }
      &::after {
        content: '';
        position: absolute;
        width: 0%;
        height: 0.2rem;
        left: 0;
        bottom: -1.5rem;
        background-color: #f3ff5c;
        transition: all 0.3s 0s ease;
      }
      &--green {
        font-size: 1.4rem;
        color: #008b8b;
        position: relative;
        @include header(1529px) {
          font-size: 1.2rem;
        }
      }
    }
    &Wrap {
      @include pc {
        margin-left: 2rem;
        flex: 1;
      }
      @include sp {
        display: contents;
      }
      &::after {
        background-color: #fff;
        content: '';
        width: 0vw;
        height: 0vh;
        z-index: -17;
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  &_sentence {
    font-size: 1.2rem;
    @include sp {
      font-size: 1rem;
    }
  }
  &_str {
    &Box {
      padding: 1rem 2rem;
      background-color: #008b8b;
      color: #f3ff5c;
      margin-bottom: 1rem;
      @include header(1529px) {
        width: 7rem;
        padding: 0.6rem 0.3rem;
        margin: 0 auto 0.8rem;
      }
      @include sp {
        font-family: 'Futura';
        padding: 0.7rem 1.4rem;
        margin-bottom: 0.8rem;
      }
    }
  }
  &_arrow {
    right: 10%;
    position: absolute;
    display: block;
    width: 1rem;
    height: 1rem;
    border-top: solid 0.2rem #fff;
    border-right: solid 0.2rem #fff;
    transform: rotate(45deg);
    &--green {
      @extend .l-header_arrow;
      border-top: solid 0.2rem #18a8a8;
      border-right: solid 0.2rem #18a8a8;
    }
  }
}

.l-header_navContact:hover > .l-header_strBox {
  @include pc {
    color: #f5f5f5;
  }
}

.l-header_navLink:hover::after {
  @include pc {
    background-color: #f3ff5c;
    width: 100%;
  }
}
