.u-pb-10 {
  padding-bottom: 1rem;
}

.u-pb-20 {
  padding-bottom: 2rem;
}

.u-pb-40 {
  padding-bottom: 4rem;
}

.u-pb-50 {
  padding-bottom: 5rem;
}

.u-pb-27 {
  padding-bottom: 2.7rem;
}

.u-sp-image {
  @include sp {
    padding-bottom: 1.5rem;
  }
}
