.p-outline {
  &_inner {
    max-width: 102.4rem;
    margin: 0 auto;
    @include sp {
      width: 90%;
    }
  }
  &_text {
    font-size: 1.6rem;
    color: #18a8a8;
    line-height: 1.5;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_container {
    display: flex;
    border: 0.1rem solid #707070;
    &Wrap {
      padding-bottom: 4rem;
    }
    &--even {
      border-top: none;
      background-color: #f5f5f5;
    }
  }
  &_item {
    border-collapse: collapse;
    border: 0.1rem solid #333;
    padding: 2.5rem 2.3rem;
    vertical-align: middle;
    @include sp {
      padding: 1rem;
    }
    &--wrap {
      width: 16%;
      @include sp {
        white-space: nowrap;
      }
    }
  }
  &_wrap {
    padding: 3rem;
    @include sp {
      padding: 3rem 1.5rem;
    }
  }
  &_circle {
    width: 2rem;
    height: 2rem;
    background-color: #18a8a8;
    border-radius: 50%;
    margin: 0 auto;
  }
  &_band {
    margin-bottom: 5rem;
    background: #008b8b;
    width: 90%;
    color: #fff;
    text-align: center;
    padding: 2.4rem 0;
    font-size: 2.4rem;
    @include tab {
      font-size: 2rem;
    }
    @include sp {
      margin-bottom: 3rem;
      font-size: 1.6rem;
      padding: 1.2rem 0;
      line-height: 1.3;
    }
  }
  &_table {
    max-width: 92rem;
    margin: 0 auto;
    width: 100%;
    @include sp {
      overflow-x: scroll;
      & > img {
        width: 74rem;
        max-width: initial;
      }
    }
  }
}
