.p-contact {
  &_text {
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    line-height: calc(28 / 16);
  }
  &_inner {
    max-width: 106.4rem;
    width: 100%;
    padding: 0 2rem;
    margin: 0 auto;
    box-sizing: border-box;
    @include sp {
      width: 90%;
      padding: 0;
    }
  }
  &_wrap {
    max-width: 92rem;
    margin: 0 auto;
    padding-bottom: 12rem;
    @include sp {
      padding-bottom: 0;
    }
  }
  &_table {
    width: 100%;
    border: 0.1rem solid #18a8a8;
  }
  &_phone {
    &Box {
      display: flex;
      align-items: flex-end;
      @include sp {
        margin-bottom: 1rem;
        justify-content: center;
      }
    }
    &Frame {
      border-top: 0.1rem solid #18a8a8;
      border-bottom: 0.1rem solid #18a8a8;
      padding: 1.8rem 0;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      @include sp {
        display: block;
        padding: 0.9rem 0;
      }
    }
    &Number {
      color: #18a8a8;
      font-size: 2.5rem;
      font-weight: bold;
      margin-left: 0.5rem;
      @include sp {
        font-size: 2rem;
      }
      &--small {
        font-size: 1.8rem;
      }
    }
    &Fax {
      font-size: 1.6rem;
      margin-left: 0.5rem;
      @include sp {
        font-size: 1.4rem;
        text-align: center;
        margin-left: 0;
      }
    }
  }
  &_specify {
    padding-top: 1rem;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_question {
    color: #18a8a8;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.2;
    @include sp {
      font-size: 1.6rem;
    }
  }
  &_field {
    background-color: #d9ffff;
    padding: 2.4rem;
    border-collapse: collapse;
    vertical-align: middle;
    max-width: 27%;
    width: 100%;
    @include pc {
      border-right: 0.1rem solid #18a8a8;
    }
    @include sp {
      display: block;
      max-width: none;
      width: auto;
      padding: 1.5rem;
    }
  }
  &_answer {
    width: 100%;
    height: 4.5rem;
    padding-left: 1rem;
    box-sizing: border-box;
    &--small {
      width: 40%;
      padding-left: 1rem;
      @include sp {
        padding-left: 0;
        width: 100%;
      }
    }
  }
  &_textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    font-family: inherit;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_select {
    appearance: none;
    width: 100%;
    height: 4.5rem;
    padding-left: 0.5rem;
    &Wrap {
      position: relative;
    }
    &Triangle {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1.3rem 0.75rem 0 0.75rem;
      border-color: #18a8a8 transparent transparent transparent;
      top: 35%;
      left: 90%;
      @include sp {
        border-width: 1.1rem 0.75rem 0 0.75rem;
      }
    }
  }
  &_input {
    padding: 4rem;
    border-collapse: collapse;
    border-top: 0.1rem solid #18a8a8;
    vertical-align: middle;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
      padding: 2rem;
      display: block;
    }
  }
  &_require {
    padding: 2rem 0 3rem 1.5rem;
    font-size: 1.6rem;
    line-height: calc(28 / 16);
    letter-spacing: 0.1em;
    @include sp {
      padding: 2rem 0 3rem;
      font-size: 1.4rem;
    }
    &Text {
      color: #a8184a;
      font-size: 1.4rem;
    }
  }
  &_box {
    background-color: #d9ffff;
    padding: 3rem;
    margin: 4rem 0 8rem;
    @include sp {
      margin: 4rem 0;
    }
  }
  &_about {
    font-size: 1.6rem;
    text-align: center;
    line-height: 1.75;
    padding: 4rem 0 2.5rem;
    letter-spacing: 0.1em;
    @include sp {
      padding: 2rem 0 1.3rem;
      font-size: 1.4rem;
    }
  }
  &_notice {
    width: 80%;
    margin: 0 auto;
    line-height: 1.75;
    padding-bottom: 3rem;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
      text-align: justify;
      padding-bottom: 1.5rem;
      width: 100%;
    }
  }
  &_terms {
    width: 100%;
    line-height: 1.75;
    box-sizing: border-box;
    &Wrap {
      padding-bottom: 3rem;
    }
  }
  &_radio {
    &Group {
      display: flex;
      padding-left: 3rem;
      flex-wrap: wrap;
      font-size: 1.6rem;
      @include sp {
        flex-direction: column;
        font-size: 1.4rem;
      }
    }
    &Item {
      width: 50%;
      padding-bottom: 2rem;
      @include sp {
        width: 100%;
      }
    }
  }
  &_phantom {
    display: none;
  }
  &_check {
    text-align: center;
    margin-left: 2rem;
    &Input {
      display: none;
    }
  }
  &_title {
    text-align: center;
    font-size: 2.4rem;
    font-weight: bold;
    color: #18a8a8;
    border-bottom: 0.1rem solid #18a8a8;
    padding-bottom: 2rem;
    display: inline-block;
    @include sp {
      font-size: 1.6rem;
      padding-bottom: 1rem;
    }
    &Wrap {
      text-align: center;
      padding-bottom: 2rem;
    }
  }
  &_submit {
    font-size: 2.4rem;
    font-weight: bold;
    color: #fff;
    background-color: #18a8a8;
    border: none;
    text-align: center;
    width: 100%;
    padding: 2.2rem;
    max-width: 44rem;
    cursor: pointer;
    display: block;
    box-sizing: border-box;
    appearance: none;
    border-radius: 0;
    @include sp {
      font-size: 1.6rem;
      padding: 1.2rem;
      width: 48%;
    }
    &--grey {
      background-color: #acacac;
    }
    &--large {
      max-width: 68rem;
      margin: 0 auto;
      width: 100%;
      @include sp {
        width: 100%;
      }
    }
  }
  &_breadcrumb {
    display: flex;
    width: 100%;
    padding: 2rem 0;
    &Item {
      display: block;
      width: 32%;
      background-color: #d9ffff;
      color: #18a8a8;
      position: relative;
      text-align: center;
      height: 4.6rem;
      line-height: 4.6rem;
      font-size: 1.8rem;
      font-weight: bold;
      @include sp {
        font-size: 1.6rem;
      }
      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-top: 2.3rem solid transparent;
        border-bottom: 2.3rem solid transparent;
        border-left: 3rem solid #d9ffff;
        position: absolute;
        top: 0%;
        left: 100%;
        z-index: 2;
        @include sp {
          border-top: 2.3rem solid transparent;
          border-bottom: 2.3rem solid transparent;
          border-left: 1.5rem solid #d9ffff;
        }
      }
      &--active {
        background-color: #18a8a8;
        color: #fff;
        z-index: 3;
        &::after {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border-top: 2.3rem solid transparent;
          border-bottom: 2.3rem solid transparent;
          border-left: 3rem solid #18a8a8;
          position: absolute;
          top: 0%;
          left: 100%;
          z-index: 3;
          @include sp {
            border-top: 2.3rem solid transparent;
            border-bottom: 2.3rem solid transparent;
            border-left: 1.5rem solid #18a8a8;
          }
        }
      }
    }
  }
  &_thanks {
    &Title {
      font-size: 2.4rem;
      font-weight: bold;
      color: #18a8a8;
      text-align: center;
      padding: 4rem 0;
      @include sp {
        padding: 2rem 0;
        font-size: 2.2rem;
      }
    }
    &Sentence {
      line-height: 2.5;
      max-width: 69rem;
      margin: 0 auto;
      font-size: 1.6rem;
      text-align: center;
      @include sp {
        font-size: 1.4rem;
      }
    }
  }
  &_container {
    display: flex;
    justify-content: space-between;
    padding-top: 6rem;
    @include sp {
      padding-top: 3rem;
    }
  }
}

tr + tr td.p-contact_field {
  border-top: 0.1rem solid #18a8a8;
}
