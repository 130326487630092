@import 'service/transport';
@import 'service/separate';
@import 'service/crush';
@import 'service/dredge';

.p-service {
  &_inner {
    max-width: 106.4rem;
    width: 100%;
    padding: 0 2rem;
    margin: 0 auto;
    box-sizing: border-box;
    @include sp {
      width: 90%;
      padding: 0;
    }
  }
  &_text {
    font-size: 1.6rem;
    line-height: 1.75;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &Title {
    &_container {
      display: flex;
      align-items: center;
      border: 0.2rem solid #18a8a8;
      padding: 1.5rem;
      margin: 0 auto;
      background-color: #fff;
      @include sp {
        display: block;
        padding: 1rem;
      }
    }
    &_text {
      background-color: #18a8a8;
      text-align: center;
      color: #fff;
      font-size: 3.6rem;
      padding: 1rem;
      max-width: 24rem;
      width: 100%;
      margin: 0 8% 0 0;
      position: relative;
      box-sizing: border-box;
      line-height: 1.5;
      @include sp {
        font-size: 2.4rem;
        margin: 0 auto 10%;
        max-width: 100%;
      }
      &::after {
        content: '';
        position: absolute;
        width: 0.1rem;
        height: 80%;
        background-color: #18a8a8;
        left: 105%;
        top: 50%;
        transform: translateY(-50%);
        @include sp {
          width: 80%;
          height: 0.1rem;
          left: 50%;
          top: 115%;
          transform: translateX(-50%);
        }
      }
    }
    &_small {
      font-size: 1.8rem;
      display: block;
      line-height: 1.3;
      @include sp {
        font-size: 1.6rem;
      }
    }
    &_description {
      font-size: 2.4rem;
      color: #18a8a8;
      line-height: 1.5;
      @include sp {
        font-size: 1.4rem;
      }
    }
  }
  &_subTitle {
    padding: 1rem 4rem;
    border-bottom: 0.1rem solid #18a8a8;
    font-size: 2.4rem;
    color: #18a8a8;
    @include sp {
      padding: 1rem 0;
      font-size: 2rem;
    }
  }
  &_empty {
    width: 20rem;
    @include sp {
      display: none;
    }
  }
  &_arrow {
    background-color: #18a8a8;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
    top: 35%;
    left: 0;
    @include sp {
      margin: 2rem auto;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1rem;
      height: 1rem;
      border-top: solid 0.2rem #fff;
      border-right: solid 0.2rem #fff;
      transform: translate(-50%, -50%) rotate(45deg);
      @include sp {
        transform: translate(-50%, -50%) rotate(135deg);
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6rem;
      height: 0.2rem;
      background-color: #18a8a8;
      @include sp {
        width: 0.2rem;
        height: 6rem;
      }
    }
  }
  &Definition {
    &_wrap {
      border: 0.1rem solid #1aa8a8;
    }
    &_list {
      display: flex;
      width: 29rem;
      @include sp {
        width: 100%;
      }
      & > .p-serviceDefinition_term {
        border-bottom: 0.1rem solid #fff;
      }
      &:last-child > .p-serviceDefinition_term {
        border-bottom: 0.1rem solid #1aa8a8;
      }
      &:not(:last-child) > .p-serviceDefinition_description {
        border-bottom: 0.1rem solid #1aa8a8;
      }
    }
    &_term {
      width: 45%;
      background-color: #18a8a8;
      color: #fff;
      font-size: 1.8rem;
      padding: 1rem;
      box-sizing: border-box;
      text-align: center;
      display: block;
      @include sp {
        font-size: 1.6rem;
      }
    }
    &_description {
      flex: 1;
      display: block;
      text-align: center;
      background-color: #f5f5f5;
      color: #18a8a8;
      font-size: 1.8rem;
      padding: 1rem;
      box-sizing: border-box;
      @include sp {
        font-size: 1.6rem;
      }
    }
  }
  &Menu {
    &_background {
      background-color: #f5f5f5;
      padding: 4rem 0;
      @include sp {
        padding: 3rem 0;
      }
    }
    &_container {
      flex-wrap: wrap;
      @include menu {
        max-width: 51.2rem;
      }
    }
    &_list {
      &:nth-child(-n + 2) {
        @include menu {
          margin-bottom: 2rem;
        }
      }
      &:not(:last-child) {
        @include sp {
          margin: 0 auto 2rem;
        }
      }
    }
  }
}
