.wp-pagenavi {
  text-align: center;
  a,
  span {
    text-decoration: none;
    border: 0.1rem solid #18a8a8;
    padding: 1.5rem 2rem;
    margin: 0.2rem;
    font-size: 1.6rem;
    @include sp {
      padding: 0.8rem 1.2rem;
    }
  }
  a:hover,
  span.current {
    color: #fff;
    background-color: #18a8a8;
    border-color: #18a8a8;
  }
}
