.p-service {
  &Crush {
    &_image {
      max-width: 42.9rem;
      width: 100%;
      margin: 0 auto;
    }
    &_item {
      max-width: 27rem;
      width: 100%;
      margin: 0 auto 2rem;
      @include sp {
        margin: 0 auto 1rem;
      }
    }
    &_background {
      background-color: #fff;
      padding: 4rem 0;
    }
    &_box {
      display: flex;
      align-items: center;
      padding: 4rem;
      background-color: #f5f5f5;
      margin: 0 auto 4rem;
      @include sp {
        display: block;
        padding: 2rem;
        margin: 0 auto 2rem;
      }
      &Image {
        max-width: 43rem;
        width: 100%;
        margin-right: 6rem;
        @include sp {
          margin: 0 auto 2rem;
        }
      }
    }
    &_container {
      display: flex;
      flex-wrap: wrap;
      max-width: 100rem;
      padding: 4rem;
      margin: 0 auto;
      justify-content: space-between;
      @include process {
        max-width: 67rem;
      }
      @include sp {
        padding: 2rem 0;
        display: block;
      }
    }
    &_list {
      @include process {
        &:not(:last-child) {
          margin: 0 auto 2rem;
        }
      }
    }
    &_text {
      font-size: 1.6rem;
      font-weight: bold;
      color: #18a8a8;
      text-align: center;
      @include sp {
        font-size: 1.4rem;
      }
    }
    &_empty {
      flex: 1;
    }
  }
}
