html {
  @include sp {
    font-size: calc(100vw / 32);
  }
}

body {
  font-family: 'Noto Sans', sans-serif;
}

@media all and (-ms-high-contrast: none) {
  body {
    font-family: 'メイリオ', Meiryo;
  }
}

img {
  width: 100%;
  display: block;
  height: auto;
}

p {
  letter-spacing: 0.1em;
}

.pc {
  @include pc {
    display: block;
  }

  @include sp {
    display: none;
  }
}

.sp {
  @include pc {
    display: none;
  }

  @include sp {
    display: block;
  }
}

.sp-min {
  display: none;
  @include sp2 {
    display: block;
  }
}

.sp-flex {
  @include pc {
    display: none;
  }

  @include sp {
    display: flex;
  }
}

::placeholder {
  color: #acacac;
}
