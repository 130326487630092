.u-carousel {
  padding-bottom: 5rem;
  background-color: #18a8a8;
  flex: 1;
  @include sp {
    padding-bottom: 3.5rem;
  }
}

.u-pagination {
  @include pc {
    bottom: 2rem !important;
    width: auto !important;
    left: 10% !important;
  }
  span {
    border-radius: 0%;
    width: 6rem;
    height: 0.3rem;
  }
}

.swiper-slide {
  img {
    width: 100%;
  }
}

.swiper-pagination-bullet {
  background-color: #fff !important;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #f3ff5c !important;
}
