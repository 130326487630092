.c-drawer {
  &_check {
    display: none;
  }
  &_box {
    position: fixed;
    width: 2.5rem;
    height: 2rem;
    right: 1.8rem;
    top: 1.8rem;
  }
  &_close {
    font-size: 0;
  }
  &_open {
    font-size: 1rem;
  }
  &_text {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
  }
  &_icon {
    left: 0.3rem;
    &:nth-child(1) {
      display: block;
      height: 0.2rem;
      width: 2.5rem;
      border-radius: 0.3rem;
      background: #fff;
      transition: 0.5s;
      position: absolute;
      bottom: calc(100% - 0.4rem);
    }
    &:nth-child(2) {
      display: block;
      height: 0.2rem;
      width: 1.2rem;
      border-radius: 0.3rem;
      background: #fff;
      transition: 0.5s;
      position: absolute;
      top: 0.9rem;
    }
    &:nth-child(3) {
      display: block;
      height: 0.2rem;
      width: 2.5rem;
      border-radius: 0.3rem;
      background: #fff;
      transition: 0.3s;
      position: absolute;
      top: calc(100% - 0.4rem);
    }
  }
}

#drawer-check:checked ~ .c-drawer_box .c-drawer_open {
  color: rgba(255, 255, 255, 0);
  font-size: 0;
}

#drawer-check:checked ~ .c-drawer_box .c-drawer_close {
  opacity: 1;
  font-size: 1rem;
}

#drawer-check:checked ~ .c-drawer_box .c-drawer_icon:nth-child(1) {
  bottom: calc(100% - 1.1rem);
  transform: rotate(45deg);
  left: 0.5rem;
}

#drawer-check:checked ~ .c-drawer_box .c-drawer_icon:nth-child(2) {
  background-color: rgba(255, 255, 255, 0);
}

#drawer-check:checked ~ .c-drawer_box .c-drawer_icon:nth-child(3) {
  top: calc(100% - 1.1rem);
  transform: rotate(-45deg);
  left: 0.5rem;
}

#drawer-check:checked ~ .l-header_navWrap > .l-header_nav > .l-header_navItem {
  &:nth-child(1) {
    @include sp {
      border-top: 0.1rem solid #fff;
    }
  }
  @include sp {
    height: 5.5rem;
    border-bottom: 0.1rem solid #f3ff5c;
  }
}

#drawer-check:checked ~ .l-header_navWrap::after {
  width: 100vw;
  height: 100vh;
}
