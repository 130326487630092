.c-title {
  font-family: 'Futura';
  font-size: 6rem;
  font-weight: normal;
  padding-bottom: 2.8rem;
  &--yellow {
    color: #f3ff5c;
  }
  &--green {
    color: #18a8a8;
  }
}
