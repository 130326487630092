.l-inner {
  max-width: 1024px;
  margin: 0 auto;
  @include sp {
    width: 95%;
  }
}

.l-wrap {
  max-width: 1280px;
}
