.c-slide {
  &_button {
    width: 100%;
    height: 5rem;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #fff;
    &--green {
      background-color: #008b8b;
      width: 100%;
      height: 5rem;
      transition: all 0.1s linear;
      transform: translateX(50%);
    }
    &::before {
      content: '';
      background-color: #008b8b;
      width: 100%;
      height: 5rem;
      transition: all 0.1s linear;
      transform: translateX(50%);
      position: absolute;
    }
    &:hover {
      &::before {
        transform: translateX(0%);
        width: 100%;
        height: 5rem;
      }
      div {
        transform: translateX(0%);
        width: 100%;
        height: 5rem;
      }
      p.c-slide_text {
        position: absolute;
        color: #fff;
        transform: translateX(50%);
      }
      p.c-slide_arrow {
        position: absolute;
        color: #fff;
        transform: translateX(-50%) rotate(45deg);
      }
    }
  }
  &_text {
    position: absolute;
    left: 7%;
    color: #008b8b;
    z-index: 2;
    transition: all 0.1s linear;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.2rem;
      left: 3%;
    }
  }
  &_arrow {
    color: #fff;
    z-index: 2;
    transition: all 0.1s linear;
    position: absolute;
    left: 71%;
    display: block;
    width: 1rem;
    height: 1rem;
    border-top: solid 0.2rem #fff;
    border-right: solid 0.2rem #fff;
    transform: rotate(45deg);
  }
}
