.p-iso {
  &_inner {
    max-width: 106.4rem;
    width: 100%;
    padding: 0 2rem;
    margin: 0 auto;
    box-sizing: border-box;
    @include sp {
      width: 90%;
      padding: 0;
    }
  }
  &_top {
    padding-bottom: 6rem;
  }
  &_wrap {
    padding: 3.5rem;
    @include sp {
      padding: 1.5rem;
    }
  }
  &_order {
    counter-reset: item;
    list-style-type: none;
    padding-left: 0;
    line-height: 3;
    @include sp {
      line-height: 2;
    }
    &--nest {
      padding-left: 2%;
      line-height: 2;
    }
  }
  &_list {
    font-size: 1.6rem;
    text-indent: -1em;
    padding-left: 1em;
    @include sp {
      font-size: 1.4rem;
      & + & {
        margin-top: 1rem;
      }
    }
    &::before {
      counter-increment: item;
      content: counter(item) '.';
      padding-right: 0.5em;
      color: #18a8a8;
    }
  }
  &_nest {
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_text {
    font-size: 1.6rem;
    line-height: 1.75;
    @include sp {
      font-size: 1.4rem;
    }
  }
}
