.p-service {
  &Transport {
    &_background {
      background-color: #fff;
      padding: 4rem 0;
    }
    &_box {
      padding-bottom: 4rem;
      background-color: #f5f5f5;
      display: flex;
      padding: 4rem;
      margin: 0 auto 2rem;
      @include sp {
        display: block;
        padding-bottom: 3rem;
        padding: 4rem 0 0;
      }
      &Image {
        max-width: 43rem;
        width: 100%;
        margin: 0 6rem 0 0;
      }
      &Text {
        padding: 2rem;
      }
    }
    &_container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 100rem;
      width: 100%;
      padding: 4rem;
      margin: 0 auto;
      box-sizing: border-box;
      @include sp {
        display: block;
        margin: 2rem auto;
        padding: 2rem 0;
      }
      &Image {
        max-width: 54rem;
        width: 100%;
        margin: 0 10% 0 0;
        @include sp {
          width: 90%;
          margin: 0 auto 2rem;
        }
      }
      &Text {
        max-width: 100rem;
        width: 100%;
        padding: 0 4rem;
        margin: 0 auto 4rem;
        box-sizing: border-box;
        @include sp {
          margin: 0 auto 2rem;
          padding: 0;
        }
      }
    }
    &_illust {
      max-width: 100rem;
      width: 100%;
      padding: 4rem;
      box-sizing: border-box;
      @include sp {
        padding: 2rem 0 0;
      }
    }
  }
}
