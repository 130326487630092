.c-scrollDown {
  width: 13.6rem;
  height: 33rem;
  background-color: #18a8a8;
  text-align: center;
  @include header(1529px) {
    width: 9.6rem;
  }
  @include tab {
    height: 26rem;
  }
  &_str {
    writing-mode: vertical-lr;
    position: relative;
    top: 4rem;
    font-size: 1.2rem;
    padding: 0.6rem;
    color: #fff;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      width: 0.2rem;
      height: 8.5rem;
      background: #f3ff5c;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      top: 0;
      width: 0.2rem;
      height: 8.5rem;
      background: #008b8b;
      animation: pathmove 1.8s ease-in-out infinite;
      opacity: 0;
    }
  }
}

@keyframes pathmove {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 3rem;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 8.5rem;
    opacity: 1;
  }
}
