.c-menu {
  &_container {
    display: flex;
    justify-content: space-between;
    max-width: 102.4rem;
    margin: 0 auto;
    @include sp {
      display: block;
    }
  }
  &_link {
    background-color: #18a8a8;
    width: 23rem;
    color: #fff;
    padding: 1.5rem;
    position: relative;
    font-size: 1.8rem;
    display: block;
    box-sizing: border-box;
    text-align: center;
    @include sp {
      font-size: 1.6rem;
      margin: 0 auto;
    }
    &::after {
      content: '';
      position: absolute;
      right: 5%;
      top: 50%;
      display: block;
      width: 0.8rem;
      height: 0.8rem;
      border-top: solid 0.2rem #fff;
      border-right: solid 0.2rem #fff;
      transform: translateY(-50%) rotate(135deg);
    }
  }
  &_small {
    font-size: 1.4rem;
    @include sp {
      font-size: 1rem;
    }
  }
}
