.c-subTitle {
  padding: 1rem 4rem;
  position: relative;
  font-size: 2.4rem;
  font-weight: bold;
  color: #18a8a8;
  line-height: 1.2;
  @include sp {
    padding: 1rem 0;
    font-size: 2rem;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 0.1rem solid #18a8a8;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60%;
    border-bottom: 0.4rem solid #18a8a8;
  }
}
