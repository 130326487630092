.p-service {
  &Separate {
    &_background {
      padding: 4rem 0;
      background-color: #d9ffff;
      &--white {
        background-color: #fff;
        @include sp {
          padding: 2rem 0;
        }
      }
    }
    &_frame {
      padding: 4rem;
      background-color: #f5f5f5;
      @include sp {
        padding: 2rem;
      }
    }
    &_text {
      font-size: 1.6rem;
      font-weight: bold;
      color: #18a8a8;
      text-align: center;
      @include sp {
        font-size: 1.4rem;
      }
    }
    &_container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 100rem;
      width: 100%;
      margin: 0 auto;
      padding: 4rem;
      box-sizing: border-box;
      @include sp {
        justify-content: center;
        padding: 2rem 0 0;
        width: 90%;
      }
    }
    &_list {
      &:nth-last-child(n + 5) {
        @include pc {
          margin-bottom: 4rem;
        }
      }
      &:not(:last-child) {
        @include sp {
          margin-bottom: 2rem;
        }
      }
    }
    &_image {
      max-width: 20rem;
      width: 100%;
      margin: 0 auto 1rem;
      @include sp {
        max-width: 100%;
      }
    }
  }
}
