.u-bg_lightgray {
  background-color: #f5f5f5;
}

.u-bg_skyblue {
  background-color: #d9ffff;
}

.u-bg_white {
  background-color: #fff;
}

.u-c_lightgray {
  color: #f5f5f5;
}
