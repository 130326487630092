.p-service {
  &Dredge {
    &_background {
      padding: 4rem 0;
      background-color: #d9ffff;
      &--white {
        background-color: #fff;
      }
    }
    &_wrap {
      max-width: 100rem;
      width: 100%;
      padding: 4rem;
      box-sizing: border-box;
      margin: 0 auto;
      @include sp {
        padding: 2rem;
      }
    }
    &_image {
      max-width: 50rem;
      width: 100%;
    }
    &_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4rem;
      background-color: #f5f5f5;
      max-width: 100rem;
      @include sp {
        display: block;
        padding: 2rem;
      }
      &--white {
        background-color: #fff;
      }
      &Image {
        max-width: 43rem;
        width: 100%;
        margin: 0 6rem 0 0;
        @include sp {
          margin: 0 auto 2rem;
        }
        &--large {
          max-width: 48.5rem;
          width: 100%;
          @include sp {
            margin: 0 auto;
          }
        }
      }
      &Text {
        max-width: 43rem;
        width: 100%;
        @include sp {
          margin: 0 auto;
        }
        &--left {
          margin-right: 6rem;
          @include sp {
            margin: 0 auto 2rem;
          }
        }
      }
    }
  }
}
