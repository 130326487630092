.c-logo {
  display: flex;
  align-items: center;
  max-width: 29.4rem;
  width: 100%;
  @include sp {
    width: 80%;
  }
  &_name {
    font-family: 'Futura';
    color: #f3ff5c;
    &--footer {
      @extend .c-logo_name;
      font-size: 2.9rem;
      @include sp {
        font-size: 2rem;
      }
    }
    &--header {
      @extend .c-logo_name;
      font-size: 2.5rem;
      @include sp {
        font-size: 2.1rem;
      }
    }
  }
  &_ruby {
    font-size: 1.4rem;
  }
}
