.p-list {
  &_top {
    padding-bottom: 6rem;
  }
  &_inner {
    max-width: 106.4rem;
    width: 100%;
    padding: 0 2rem 8rem;
    margin: 0 auto;
    box-sizing: border-box;
    @include sp {
      width: 90%;
      padding: 0 0 4rem;
    }
  }
  &_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  &_title {
    margin-bottom: 4rem;
    @include sp {
      margin-bottom: 2rem;
    }
  }
  &_name {
    color: #fff;
    background: #18a8a8;
    text-align: center;
    padding: 1.2rem 0;
    letter-spacing: 0;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
    &--small {
      font-size: 1.4rem;
      @include sp {
        font-size: 1.2rem;
      }
    }
  }
  &_item {
    padding-bottom: 4rem;
  }
  &_number {
    border-bottom: 0.1rem #18a8a8 solid;
    padding: 1rem 0;
    text-align: center;
    color: #18a8a8;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_list {
    border: 0.1rem solid #707070;
    padding: 3rem;
    color: #18a8a8;
    border-bottom: none;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    @include sp {
      font-size: 1.4rem;
      padding: 1.5rem;
      line-height: 1.5;
    }
    &:nth-child(2n) {
      background-color: #f5f5f5;
    }
    &:last-child() {
      border-bottom: 0.1rem solid #707070;
    }
  }
  &_image {
    max-width: 27.1rem;
    width: 100%;
  }
}
