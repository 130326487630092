@import 'index/about';
@import 'index/service';
@import 'index/info';
@import 'index/hero';

.p-index {
  &_wrap {
    max-width: 102.4rem;
    margin: 0 auto;
    @include sp {
      width: 90%;
    }
  }
}
