.p-news {
  &_inner {
    max-width: 102.4rem;
    margin: 0 auto;
    @include sp {
      width: 90%;
    }
  }
  &_wrap {
    padding: 5rem 0 8rem;
  }
  &_underline {
    padding: 1rem 4rem;
    border-bottom: 0.1rem solid #18a8a8;
    @include sp {
      padding: 1rem;
    }
  }
  &_ymd {
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_title {
    font-size: 3.2rem;
    padding-bottom: 1rem;
    border-bottom: 0.1rem solid #18a8a8;
    margin-bottom: 1rem;
    @include sp {
      font-size: 2.4rem;
    }
  }
  &_button {
    display: block;
    font-size: 2.4rem;
    font-weight: 700;
    color: #fff;
    background-color: #18a8a8;
    border: none;
    text-align: center;
    width: 100%;
    padding: 2.2rem;
    max-width: 44rem;
    cursor: pointer;
    margin: 0 auto;
    @include sp {
      font-size: 1.6rem;
      padding: 1.2rem;
      max-width: 15rem;
    }
  }
  &_pageNavi {
    padding-top: 3rem;
  }
  &_list {
    padding-bottom: 3rem;
    @include sp {
      padding-bottom: 1rem;
    }
  }
  &_date {
    font-weight: bold;
    color: #18a8a8;
    padding-bottom: 1.5rem;
    border-bottom: 0.1rem solid;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    display: inline-block;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
  }
  &_sentence {
    line-height: 1.6;
    font-size: 1.6rem;
    @include sp {
      font-size: 1.4rem;
    }
  }
}
