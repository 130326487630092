.c-top {
  &_image {
    img {
      width: 100%;
    }
  }
  &_title {
    position: relative;
    background-color: #18a8a8;
    width: 90%;
    text-align: center;
  }
  &_text {
    font-size: 2.8rem;
    color: #fff;
    letter-spacing: 0.6em;
    font-weight: 400;
    @include sp {
      font-size: 2rem;
    }
    &--en {
      font-size: 2rem;
      color: #f3ff5c;
      font-family: 'Futura';
      @include sp {
        font-size: 1.4rem;
      }
    }
  }
  &_inner {
    position: absolute;
    bottom: 100%;
    width: 100%;
    border-radius: 2rem 0 0;
    background: none;
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      background: #18a8a8;
      mix-blend-mode: multiply;
      z-index: 0;
      bottom: 0;
      border-radius: 0 2rem 0 0;
    }
  }
  &_wrap {
    padding: 1%;
    position: relative;
  }
}
